import axios from 'axios';
export default {
    getPageViews (dates, success, fail) {
        axios.get('/api/analytics/getPageViews/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getPageTimes (dates, success, fail) {
        axios.get('/api/analytics/getAverageTime/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getVideoViews (dates, success, fail) {
        axios.get('/api/analytics/getVideoViews/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getClickThroughs (dates, success, fail) {
        axios.get('/api/analytics/getClickThroughs/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getVideoStats (dates, success, fail) {
        axios.get('/api/analytics/getVideoStats/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getSessionStats (dates, success, fail) {
        axios.get('/api/analytics/getSessionStats/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getUserStats (dates, success, fail) {
        axios.get('/api/analytics/getUserStats/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getWpsaClickthrough (dates, success, fail) {
        axios.get('/api/analytics/getWpsaSubmissions/' + dates.start + '/' + dates.end).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getFeedback (success, fail) {
        axios.get('/api/analytics/getFeedback/').then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    }
};