<template>
<modal title="Flexible Income" @close="$emit('close')">
  <template slot="content">
    <questions-flow ref="questions" @complete="submit">
      <question title="Introduction" :animateText="true" :order="0">
        <div class="container left">
            <div>
                <p class="animate">If you wish to obtain an estimate of the income you could receive from drawdown and for further information on this option please visit the Government’s Money Helper website linked below or please talk to your financial adviser</p>
                <p class="link">
                    <a href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/taking-your-pension/what-is-flexible-retirement-income-pension-drawdown" target="_blank">https://moneyhelper.org.uk</a>
                </p>
                <p>The site has lots of useful information about your options. You can use the modeller on this site to help you understand what your transfer value might provide in your retirement.</p>
            </div>
            <a class="money-helper" href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/taking-your-pension/what-is-flexible-retirement-income-pension-drawdown" target="_blank">
                <img src="../assets/money-helper.png"/>
            </a>
        </div>
      </question>
    </questions-flow>
  </template>
</modal>
</template>

<script>
import QuestionsFlow from './common/QuestionsFlow';
import Question from './common/Question';
import Modal from './common/Modal';

export default {
    name: 'Drawdown',
    components: { QuestionsFlow, Question, Modal },
    data () {
        return {
            mounted: false
        };
    },
    mounted () {
        this.mounted = true;
    },
    methods: {
        submit () {
            this.$emit('submit');
        }
    },
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';


p.slider-amount {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    display: inline-block;
}

.highlight{
    font-weight: bold;
}

.amount {
    color: $nationalGridBlue;
    font-weight: 700;
}

input[type="radio"] {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}
input:checked {
    + .card {
        background: $nationalGridLightBlue;
        color: white;
        h4 {
            color: white;
        }
        .amount {
            color: white;
        }
    }
}
.cards {
    display: flex;
    @include responsive (mobile) {
        flex-wrap: wrap;
    }
}

::v-deep .vue-slider-piecewise-item:nth-child(even) {
  display: none;
}

.card {
    padding: 1rem;
    margin: 0.5rem;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px 1px;
    background: #fff;
    @include responsive (mobile) {
        width: 100%;
    }
    transition: transform 0.5s;
    &:hover {
        cursor: pointer;
        transform: scale(1.01);
    }
    p {
        padding: 0;
    }
    h4 {
        font-weight: 400;
        color: $nationalGridBlue;
        font-size: 1.1rem;
    }
    &.selected {
        background: $nationalGridLightBlue;
        color: white;
        h4 {
            color: white;
        }
        .amount {
            color: white;
        }
    }
}
.changed{
    ul{
        list-style:disc;
        margin-left: 16px;
    }
    .bold{
        font-weight: bold;
    }
        padding: 0 20px;
    font-size: 1.1rem;
    text-align: left;
}

.money-helper {
    height: calc(96vh - 30rem);
    width: auto;
    border: 1rem solid #eee;
    margin-left: 0.5rem;
    position: relative;
    img {
        height: 100%;
    }
    &:after {
        content: 'moneyhelper.org.uk';
        position: absolute;
        background: #eee;
        color: #333;
        bottom: -0.5rem;
        padding: 0.5rem;
        left: 50%;
        transform: translate(-50%, 100%);
    }
}

.container {
    display: flex;
}
</style>
