<template>
<div class="options" :style="{ 'padding-bottom': (whatShouldIDoNowVisible ? '0' : '10rem') }">
    <div class="grey-curve" :style="{ 'background-image': 'url(' + schemeDetails.lowerCurve + ')' }" />
    <div class="container">
        <div class="line straight" />
        <div class="end-of-pipe" />
        <div class="option-count">
            <p>Planning your retirement</p>
        </div>
        <h2 class="options-you-have">The options you have</h2>
        <h3 class="four-options">
            You have options within the Scheme and outside the Scheme. We suggest watching each video below to give
            you an explanation of each option.
            There are advantages and disadvantages to each of these options and My Retirement Planner is designed to help you understand these but not to tell you what to do.  You don't need to make any decisions now; you can just find out more about the different options.
            <b><span v-if="user.status == 'active'">If you decide to draw your Scheme pension or transfer out you will no longer be able to build up further benefits in the Scheme.</span></b>
        </h3>
         <div class="information-section">
            <h3>First, some words of warning... don't let a scammer enjoy your retirement</h3>
            <p>Pension scams can be hard to spot. Scammers can be convincing and financially knowledgeable, with credible-looking websites, testimonials and materials that are hard to distinguish from the real thing.  Be very wary of this when making any decisions about transferring your pension and read the guidance on how to avoid pension scams available <a href="https://www.fca.org.uk/scamsmart/how-avoid-pension-scams?gclid=EAIaIQobChMI3brZq5a46AIVgoXVCh0-IwsBEAAYASAAEgLnJPD_BwE" target="_blank">here</a> and the ScamSmart <a href="https://www.fca.org.uk/publication/documents/pension-scams-leaflet.pdf" target="_blank">leaflet</a>.</p>
            <p>If you are considering transferring your pension you should also read this <a href="https://www.thepensionsregulator.gov.uk/-/media/thepensionsregulator/files/import/pdf/cetv-member-letter.ashx" target="_blank">letter</a> published issued by The Pensions Regulator, the Financial Conduct Authority (FCA) and the Money and Pensions Service.</p>
         </div>

        <div class="options-grid education">
            <div class="category primary half">
                <div class="category-title">
                    {{ schemeDetails.schemeName }} benefits
                </div>
                <ul>
                    <li class="option" @click="watchVideo('When can I receive my pension?')">
                        <div class="option-title">Scheme benefits</div>
                        <p>You have built up benefits in the {{ schemeDetails.schemeName }}<span v-if="user.status == 'active'"> and you will continue to build up more benefits whilst you remain a contributing member</span>.  In summary these benefits include:</p>
                        <ul>
                            <li>
                                A pension that will be paid to you every month from retirement for the rest of your life.
                            </li>
                            <li>
                                The pension will normally increase each year in line with increases in the cost of living.
                            </li>
                            <li>
                                Protection for your dependants, as it may provide a lump sum and a
                                pension to your dependants if you were to die before them.
                            </li>
                            <li>
                                Additional options and flexibilities within the Scheme - including giving up some of your pension for a tax-free cash sum at retirement.  Up to 25% of the value of your benefits can be taken as tax-free cash.
                            </li>
                        </ul>
                        <div class="video small" title="Watch video" :class="{ watched: getValue('schemePensionWatched') && getValue('otherOptionsWatched') }">
                            <img src="../../assets/1-when-can-i-start-thumbnail - Copy.png">
                            <span>Scheme pension (click here)</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="category primary quarter">
                <div class="category-title padding-left">
                    Transfer out to an insurance company
                </div>
                <ul>
                    <li class="option" @click="watchVideo('Pension from an insurer')">
                        <div class="or">OR</div>
                        <div class="option-title">Insurance company options</div>
                        <p>You can transfer out your {{ schemeDetails.schemeName }} benefits so that you can receive them as a regular retirement income for life but from an insurance company.</p>
                        <ul>
                            <li>You will receive a pension each month for the rest of your life.</li>
                            <li>You can customise this pension:
                                <ul>
                                <li>Choose if it increases.</li>
                                <li>Choose what benefits it provides to your dependants.</li>
                                </ul>
                            </li>
                            <li>Up to 25% of the value of your benefits can be taken as tax-free cash.</li>
                        </ul>
                        <div class="video small secondary" title="Watch video" :class="{ watched: getValue('annuityWatched') }">
                            <img src="../../assets/insurance company thumb.png">
                            <span>Pension from an insurance company (click here)</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="category primary quarter">
                <div class="category-title padding-left">
                    Transfer out to a drawdown provider
                </div>
                <ul>
                    <li class="option" @click="watchVideo('Flexible Income')">
                        <div class="or">OR</div>
                        <div class="option-title">Flexible income options</div>
                        <p>
                            You can transfer out your {{ schemeDetails.schemeName }} benefits so that you can receive a flexible income from another pension arrangement.
                        </p>
                        <ul>
                            <li>Use money as you need it.</li>
                            <li>Leave any remaining funds you don’t use to your estate.</li>
                            <li>Up to 25% of your fund can be taken as tax-free cash.</li>
                            <li>You can alternatively take the whole fund as cash in one go but you will pay tax on at least 75% of it.</li>
                        </ul>
                        <div class="video small tertiary" title="Watch video" :class="{ watched: getValue('drawdownWatched') }">
                            <img src="../../assets/flexible-income-thumbnail.png">
                            <span>Flexible income (click here)</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container" v-if="fullAccess">
        <a class="btn small mean" v-if="!getValue('whatThisMeansForMeVisible')" :class="{ greyed: !allVideosWatched }" @click="showMeansForMe">
            What does this mean for me?
        </a>
        <div class="whatWrapper" v-if="showAll || getValue('whatThisMeansForMeVisible')" style="width: 100%; margin-top: 2em">
            <div id="whatdoesthismean">
                <h2 class="next-steps">What does this mean for me?</h2>
                <h3 class="four-options" style="margin-bottom: 0rem;">
                    <span v-if="user.redundancy == 'Y'">
                        You left the Scheme because of redundancy and can therefore draw your benefits from the Scheme from age 50.
                    </span>
                    Below we show an illustration of what these benefit options could provide you if you <span v-if="user.status == 'active'">remain a contributing member until you</span> retire at <span v-if="seeSlider">the age shown on the slider below</span><span v-if="!seeSlider"> age {{ retirementAgeShow | age }}</span>.<span v-if="user.status == 'active'">  If you are made redundant whilst you are a contributing member and before reaching age {{earliestUnreducedRetirementAge}} your illustrative benefit options will differ.</span>  You can amend and customise your options by clicking on any of the benefit options below.
                    <span class="see-how-changing" v-if="user.status === 'active' && seeSlider">
                        You can also see how changing your retirement age on the sliding scale below changes your estimated retirement benefits under the various options. The
                        illustrations assume you stay in the Scheme until your retirement, accruing additional benefits.
                    </span>
                    <span class="see-how-changing" v-if="user.status !== 'active' && seeSlider">
                        You can also see how changing your retirement age on the sliding scale below changes your estimated retirement benefits under the various options.
                    </span>
                    <span v-if="user.redundancy == 'Y' && retirementAge <=55">
                        As you left because of redundancy the insurance company and drawdown figures are only shown at age 55, which is the earliest date you could normally receive money from these options.
                    </span>
                </h3>
                <h3 v-if="user.high_tv">
                    The value of your Scheme benefits are approaching or have reached the Lifetime Allowance (LTA), this means that your benefits could be subject to additional tax.  Whether you actually exceed the LTA will depend on a number of factors including the value of other pension benefits that you may have, what options you choose for your Scheme benefits, when you take your benefits and whether you have any LTA protections.  Some further details of the tax allowances can be found <a href="https://ngukgaspension.co.uk/active/how-the-scheme-works/tax-allowances" target="_blank">here</a>. We recommend you take financial advice.
                </h3>
                <div class="retirementAgeSlider" v-if="seeSlider">
                    <div class="sliderContainer" style="width: 100%; margin-top: 1.5em; margin-bottom: 0.5em">
                        <vue-slider v-model="retirementAge" :lazy="false" :piecewise="true"
                            :speed="0" :data="ageSliderDataPoints" :tooltip="false" :dotSize="28">
                            <template slot="label" slot-scope="{ label }">
                                <span class="vue-slider-piecewise-label" v-if="Math.abs(label - user.ageNow) < 0.001">Age now</span>
                                <span class="vue-slider-piecewise-label" v-if="Math.abs(Math.floor(label) - label) < 0.01">{{ label }}</span>
                            </template>
                            <template slot="piecewise" slot-scope="{ active }">
                                <span class="vue-slider-piecewise-dot year" :class="{ active: active }"/>
                            </template>
                        </vue-slider>
                    </div>
                </div>


                <div class="options-grid means-for-me">
                    <div class="category primary half">
                        <div class="category-title ">
                            {{ schemeDetails.schemeName }} benefits
                        </div>
                        <ul>
                            <li class="option summary" @click="selectOption('Scheme Pension')" :class="{ opened: getValue('schemePensionOpened') }">
                                <div class="option-title">Illustrated Scheme benefits<span class="understand">(click to understand and customise)</span></div>
                                <div class="sub-group first">
                                    <div class="sub-title blue-title">A regular income of</div>
                                    <p>
                                        <span class="highlight">£{{calcs.schemeCalc.retirement_pension | roundDown(100)}}</span>
                                        <span> pa payable from age {{retirementAgeShow | age}} for the rest of your life from the Scheme <span v-if="bridgeTheGap & retirementAge < calcs.schemeCalc.spa" >- this includes a Bridge the Gap uplift which will stop at State Pension Age (when your pension will reduce by £{{calcs.schemeCalc.post_bridge_reduction | roundDown(100)}} pa)</span></span>
                                    </p>
                                    <p v-if="calcs.schemeCalc.gmp_step_up > 0">In addition when you reach age {{calcs.schemeCalc.gmp_age}} your pension will increase by approximately £{{calcs.schemeCalc.gmp_step_up | roundDown(100)}} pa (click <a href="https://ngukgaspension.co.uk/pensioner-or-dependants/your-pension/increase-and-payment-dates" target="_blank">here</a> for details).</p>
                                    <p v-if="calcs.schemeCalc.gmp_step_up_applied > 0">This illustration includes an increase when you retire from age {{calcs.schemeCalc.gmp_age}} – these figures are not guaranteed as it will depend on future increases in the cost of living and other factors (click <a href="https://ngukgaspension.co.uk/pensioner-or-dependants/your-pension/increase-and-payment-dates" target="_blank">here</a>).</p>
                                    <p>Your pension will increase each year in line with increases in the cost of living</p>
                                </div>
                                <div class="sub-group second">
                                    <div class="sub-title orange-title">plus a lump sum at {{retirementAgeShow | age}} of</div>
                                    <p>
                                        <span class="highlight">£{{calcs.schemeCalc.retirement_pcls | roundDown(500)}}</span>
                                        <span> tax free </span>
                                        <span v-if="calcs.schemeCalc.avcs_used_in_commutation > 0">including £{{calcs.schemeCalc.avcs_used_in_commutation | roundDown(500)}} from your AVCs</span>
                                    </p>
                                </div>
                                <div class="sub-group third">
                                    <div class="sub-title green-title">and benefits for your spouse or dependants, including:</div>
                                    <p>
                                        After your death, a pension of
                                        <span class="highlight">£{{calcs.schemeCalc.retirement_sp_pen | roundDown(100)}}</span> pa would be paid to your spouse, civil partner or in some cases to other dependants
                                    </p>
                                </div>
                                <div class="sub-group fourth">
                                    <div class="sub-title blue-title">Key things to be aware of</div>
                                    <p>Secure income guaranteed for life with protection for your dependants and against increases in the cost of living but with limited flexibility</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="user.redundancy == 'Y' && retirementAge < 55" class="category primary half redundancy">
                        <div class="category-title padding-left">
                            Transfer out to an insurance company or drawdown provider
                        </div>
                        <ul>
                            <li class="option summary">
                                <div class="sub-group">
                                    <p>
                                        You can transfer out your {{ schemeDetails.schemeName }} benefits before the age of 55.  However, <span class="highlight">you will normally be unable to take draw a benefit from an insurance company or drawdown provider before age 55.</span>
                                    </p>
                                    <p>
                                        You can see an illustration of these options if you change your illustrated retirement age to 55 (using the slider above).
                                    </p>
                                </div>

                            </li>
                        </ul>
                    </div>
                    <div v-if="user.redundancy == 'N' || (user.redundancy == 'Y' && retirementAge >= 55)" class="category primary quarter">
                        <div class="category-title padding-left">
                            Transfer out to an insurance company
                        </div>
                        <ul>
                            <li class="option summary" @click="selectOption('Pension from an insurer')" :class="{ opened: getValue('annuityOpened') }">
                                <div class="or">OR</div>
                                <div class="option-title">Illustrated benefits<span class="understand">(click to understand and customise)</span></div>
                                <div class="sub-group first">
                                    <div class="sub-title blue-title">A regular income of</div>
                                    <p>
                                        <span class="highlight">£{{calcs.annuityCalc.retirement_annuity | roundDown(100)}}</span>
                                        <span> pa payable from {{ retirementAgeShow | age }} for the rest of your life</span>
                                    </p>
                                    <p v-if="annuityIncreasing">This illustration assumes that your income increases each year in line with increases in the cost of living</p>
                                </div>
                                <div class="sub-group second">
                                    <div class="sub-title orange-title">plus a lump sum at {{ retirementAgeShow | age }} of</div>
                                    <p>
                                        <span class="highlight">£{{calcs.annuityCalc.retirement_pcls | roundDown(500)}}</span>
                                        <span> tax free </span>
                                    </p>
                                </div>
                                <div class="sub-group third">
                                    <div class="sub-title green-title">and benefits for your spouse or dependants, including:</div>
                                    <p v-if="annuitySpouse">After your death, a pension of <span class="highlight">£{{calcs.annuityCalc.retirement_sp_pen | roundDown(100)}}</span> pa
                                        would be paid to your spouse or other nominated dependant</p>
                                    <p v-else>No spouses benefit illustrated</p>
                                </div>
                                <div class="sub-group fourth">
                                    <div class="sub-title blue-title">Key things to be aware of</div>
                                    <p>Secure income for life with some additional flexibility</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div v-if="user.redundancy == 'N' || (user.redundancy == 'Y' && retirementAge >= 55)" class="category primary quarter">
                        <div class="category-title padding-left">
                            Transfer out to a drawdown provider
                        </div>
                        <ul>
                            <li class="option nooption summary"  @click="selectOption('Flexible Income')"
                                :class="{ opened: getValue('drawdownOpened') }">
                                <div class="or">OR</div>
                                <div class="option-title">Accessing this benefit<span class="understand">(click to find out more)</span></div>
                                <div class="sub-group first">
                                    <div class="sub-title blue-title">Regular income:</div>
                                    <p>
                                        You may be able to transfer the value of your {{ schemeDetails.schemeName }} benefits to a drawdown provider, allowing you to drawdown your investments each year.  Your transfer value was <span class="highlight"> £{{calcs.drawdownCalc.doc_tv | roundDown(1000)}}</span><span v-if="user.mp_avcs_value > 0"> (and your Money Purchase AVCs of £{{user.mp_avcs_value | roundDown(500)}})</span> as at {{user.calculation_date.format('MMMM YYYY')}}.
                                    </p>
                                </div>
                                <div class="sub-group second">
                                    <div class="sub-title orange-title">retirement lump sum</div>
                                    <p>
                                        You would normally be able to take up to 25% of the value of your fund as a tax-free cash lump sum.  Any additional funds taken would be taxed as income.
                                    </p>
                                </div>
                                <div class="sub-group third">
                                    <div class="sub-title green-title">and benefits for your spouse or dependants, including:</div>
                                    <p>Following your death, any of your remaining funds can be left to your dependants or estate</p>
                                </div>
                                <div class="sub-group fourth">
                                    <div class="sub-title blue-title">Key things to be aware of</div>
                                    <p>Most flexible option but your income is not guaranteed and you could run out of money</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a class="btn small mean" v-if="!getValue('whatShouldIDoNowVisible') && (getValue('whatThisMeansForMeVisible') || !fullAccess)"
        :class="{ greyed: !optionsComplete }" @click="showWhatDoIDoNow">
        What should I do now?
    </a>
</div>
</template>

<script>
import vueSlider from 'vue-slider-component';
export default {
    name: "options",
    props: {
        whatShouldIDoNowVisible: Boolean,
        showAll: Boolean
    },
    components: { vueSlider },
    created () {

    },
    methods: {
        selectOption (option) {
            this.setValue('selectedOption', option);
        },
        showWhatDoIDoNow () {
            if (this.fullAccess) {
                if (this.optionsComplete) {
                    this.setValue('whatShouldIDoNowVisible', true);
                    this.showFeedbackExtra()
                } else {
                    this.setValue('areYouSureWhatNow', true);
                }
                this.$emit('showWhatDoIDoNow');
            } else {
                if (this.allVideosWatched) {
                    this.setValue('whatShouldIDoNowVisible', true);
                    this.showFeedbackExtra();
                } else {
                    this.setValue('areYouSure', true);
                }
                this.$emit('showWhatDoIDoNow');
            }
        },
        showFeedbackExtra () {
            if (!localStorage.getItem('feedback')) {
                this.setValue('feedbackExtra', true);
                localStorage.setItem('feedback', true);
            }
        },
        getValue (prop) {
            return this.$store.getters.getValue(prop);
        },
        setValue (prop, value) {
            this.$store.dispatch("setValue", { prop, value });
        },
        showMeansForMe () {
            this.allVideosWatched ? this.setValue('whatThisMeansForMeVisible', true) : this.setValue('areYouSure', true);
            this.$emit('showMeansForMe');
        },
        watchVideo (video) {
            this.$emit('watchVideo', video);
        }
    },
    computed: {
        user () {
            return this.$store.getters.getUser;
        },
        isSpecial () {
            return this.user.type == 'special_case';
        },
        fullAccess () {
            return !this.isSpecial && !this.isAgeRestricted;
        },
        isAgeRestricted () {
            return this.user.type == 'age_restricted';
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        calcs () {
            return this.user == null? {
                retirementAge: null,
                schemeCalc: null,
                annuityCalc: null,
                drawdownCalc: null
            } : this.$store.getters.getCalculations;
        },
        drawdownCalc () {
            return this.$store.getters.getDrawdownCalc;
        },
        bridgeTheGap: {
            get () {
                return this.$store.getters.getBridgeTheGap;
            },
            set (newValue) {
                this.$store.dispatch('setBridgeTheGap', newValue);
            }
        },
        earliestRetirementAge (){
            return Math.max(this.user.earliest_retirement_age, this.user.ageNow);
        },
        earliestUnreducedRetirementAge () {
            return this.user.earliest_undreduced_retirement_age;
        },
        sliderMaxAge () {
            if (this.user.status == 'active') {
                return this.user.nra;
            } else {
               if (this.user.sex == 'M') {
                   return 65;
               } else {
                    if (this.user.ageNow < 60) return 65;
                   return Math.max(this.earliestUnreducedRetirementAge, 55);
               }
            }
        },
        seeSlider () {
            return this.earliestRetirementAge < this.sliderMaxAge;
        },
        ageSliderDataPoints () {
            var arr = [];

            var maxi = (this.sliderMaxAge - Math.ceil(this.earliestRetirementAge))*2 +  (this.user.ageNow < this.earliestRetirementAge? 0 :((this.earliestRetirementAge - Math.floor(this.earliestRetirementAge)) > 0.5? 1 : 2));
            for (var i = 0; i <= maxi; i++) {
                if (i == 0) {
                    arr.push(this.earliestRetirementAge);
                } else {
                    arr.push(Math.floor(this.earliestRetirementAge*2)/2 + i*0.5);
                }
            }

            return arr;
        },
        retirementAge: {
            get () {
                return this.$store.getters.getRetirementAge;
            },
            set (newValue) {
                this.$store.dispatch("setRetirementAge", newValue);
            }
        },
        retirementAgeShow () {
            return this.retirementAge;
        },
        // ageSliderDataPoints () {
        //     var arr = [];
        //     for (var i = this.earliestRetirementAge; i <= this.sliderMaxAge; i++) {
        //         arr.push(i);
        //     }
        //     return arr;
        // },
        allVideosWatched () {
            return (
                this.getValue('annuityWatched') &&
                this.getValue('drawdownWatched') &&
                this.getValue('otherOptionsWatched') &&
                this.getValue('cashWatched') &&
                this.getValue('schemePensionWatched') &&
                this.getValue('combinationWatched')
            );
        },
        optionsComplete () {
            return (
                this.getValue("annuityOpened") &&
                this.getValue("drawdownOpened") &&
                this.getValue("cashOpened") &&
                this.getValue("schemePensionOpened") &&
                this.getValue("combinationOpened")
            );
        },
        annuityIncreasing () {
            return this.$store.getters.getAnnuityIncreasingOption;
        },
        annuitySpouse () {
            return this.$store.getters.getAnnuitySpouseOption;
        },
        flexibleIncomeAmount () {
            return this.$store.getters.getFlexibleIncomeAmount;
        },
        flexibleIncomeAge () {
            return this.$store.getters.getFlexibleIncomeAge;
        }
    }
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';


.container {
  @include responsive(tablet) {
    padding: 1rem 2rem 1rem 2rem;
  }
}
h3 {
    color: #333;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    max-width: 1400px;
    &.four-options {
        margin-bottom: 28px;
        max-width: 1400px;
    }
}

.information-section {
    max-width: 1400px;
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto;
    background: #f4f4f4;
    padding: 1.5rem;
    a {
        color: $highlightColour;
        text-decoration: underline;
    }
    h3 {
        margin: 0;
    }
}
h2 {
    color: #333;
    font-size: 1.4rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    &.options-you-have {
        margin-top: 44vh;
    }
    &.next-steps {
        position: relative;
        z-index: 99;
    }
}
.highlight {
    font-weight: 600;
}
.highlight-color{
    color: $highlightColour;
}
.btn {
    background: $nationalGridOrange;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
    color: white;
    display: inline-block;
    margin-top: 0.5rem;
    padding: 1rem 0.5rem;
    text-align: center;
    transition: transform 0.5s, box-shadow 0.5s;
    width: 100%;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
        cursor: pointer;
        transform: translateY(-2px);
    }
    &.small {
        background: $nationalGridGreen;
        color: #fff;
        font-size: 1.1rem;
        left: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        padding: 1rem 2rem;
        position: relative;
        text-shadow: rgba(0, 0, 0, 0.5) 0 0 8px;
        transform-origin: 0% 50%;
        transform: translateX(-50%);
        width: auto;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
            transform: translateY(-2px) translateX(-50%);
        }
        &.greyed {
            background: lighten($highlightColour, 4);
            box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 6px;
        }
        &.introvideo {
            left: 0;
            margin-top: 2rem;
            transform: none;
        }
    }
    &.yes {
        background: #aaa;
    }
}

.buttonText {
    color: $highlightColour;
}

.vue-slider-component.vue-slider-horizontal-reverse .vue-slider-piecewise-label, .vue-slider-component.vue-slider-horizontal .vue-slider-piecewise-label{
    margin-top: .8rem;
    // margin-top: -1.15rem;
    // z-index: 2;
    // text-align: center;
    // margin-left: 0.07rem;
}
// .vue-slider-piecewise-dot.year {
//     transform: scale(1.4);
//     margin-top: -0.45rem;
//     margin-left: -0.45rem;
// }

.option-count {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 2rem;
    height: 6rem;
    position: absolute;
    right: calc(75vw - 4rem);
    text-align: center;
    top: calc(3vh + 2rem + 20vh);
    width: 12rem;
    z-index: 1;
    .count {
        font-size: 2.5rem;
    }
    p {
        font-size: 1.3rem;
    }
    @include responsive(mobile) {
        right: calc(75vw - 7rem);
    }
}

.line {
    position: absolute;
    &.straight {
        background: $lightPipeYellow;
        bottom: calc(-30vh + 13rem);
        height: calc(186vh + 11rem);
        right: calc(15vw + 1.65rem);
        width: 0.35rem;
        z-index: 2;
        &:after {
            background: $pipeYellow;
            content: '';
            height: 100%;
            left: 0.35rem;
            position: absolute;
            top: 0;
            width: 0.35rem;
        }
    }
}

.sliderLink{
    cursor: pointer;
}

.options {
    background: #e1e1e1;
    position: relative;
    top: 0;
    .line.straight {
        height: calc(26vh + 3rem);
        right: calc(75vw + 1.65rem);
        top: calc(-3vh - 1rem);
        transform-origin: top top;
        z-index: 1;
    }
    #whatdoesthismean {
        .retirementAgeSlider {
            display: flex;
            justify-content: space-around;
            margin-left: auto;
            margin-right: auto;
            max-width: 800px;
            position: relative;
            width: 100%;
            margin-bottom: 3rem;
            @include responsive(mobile) {
                flex-wrap: wrap;
            }
        }
    }
    .options-grid {
        display: flex;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        max-width: 1400px;
        position: relative;
        width: 100%;
        @include responsive(mobile) {
            flex-wrap: wrap;
        }
        .video {
            margin-top: 0.5rem;
            position: relative;
            text-align: center;
            transition: transform 0.5s;
            width: auto;
            overflow: hidden;
            &.small {
                background: #e4e4e4;
                text-align: left;
                display: flex;
                align-items: center;
                &.secondary {
                    background: transparentize($nationalGridGreen, 0.85);
                }
                &.tertiary {
                    background: transparentize($nationalGridOrange, 0.85)
                }
                img {
                    vertical-align: middle;
                    width: auto;
                    height: 50px;
                }
                span {
                    vertical-align: middle;
                    display: inline-block;
                    padding: 1rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            @include responsive(tablet) {
                // max-width: 260px;
            }
            img {
                max-width: 600px;
                width: 100%;
            }
            &.watched:after {
                background-color: transparentize($nationalGridGreen, 0.1);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: contain;
                content: 'You have watched these videos - click to watch again';
                color: #fff;
                text-align: center;
                font-size: 1rem;
                padding: 0.5rem;
                height: calc(100% - 1rem);
                width: calc(100% - 1rem);
                right: 0;
                position: absolute;
                bottom: 0;

            }
            &:hover {
                cursor: pointer;
                transform: scale(1.05);
            }
        }
        .category {
            display: flex;
            flex-direction: column;
            margin-right: 0.5rem;
            min-width: 257px;
            width: calc(66.66%);
            @include responsive(mobile) {
                margin-right: 0;
                margin-top: 0.5rem;
                width: calc(33.3% - 1rem);
            }
            @include responsive(tablet) {
                flex-grow: 1;
                margin-right: 0;
                min-width: unset;
                width: calc(66.6% - 1.5rem);
            }
            .category-title {
                background: $nationalGridLightBlue;
                border-radius: 0.15rem;
                color: white;
                font-size: 1.1rem;
                min-height: 3rem;
                padding: 1rem;
                position: relative;
                @media (max-width: 1365px) {
                    min-height: 4.15rem;
                }
                &.padding-left{
                    padding-left: 2rem;
                }
                img {
                    height: 1.5rem;
                    margin-top: 0rem;
                    position: absolute;
                    top: 0.75rem;
                    width: auto;
                }
                &.large {
                    &:hover {
                        .video {
                            transform: scale(1.1);
                        }
                        cursor: pointer;
                    }
                    .video {
                        padding: 0.5rem;
                        text-align: center;
                        transition: transform 0.5s;
                    }
                    .video img {
                        height: 200px;
                        position: relative;
                        top: auto;
                        width: auto;
                    }
                    span {
                        background: $nationalGridLightBlue;
                        display: block;
                        margin: -1rem -1rem 0 -1rem;
                        padding: 1rem;
                    }
                }
            }
            .options-banner {
                background-color: #fff;
            }
            &.primary {
                width: calc(33.3%);
                @include responsive (tablet) {
                    margin-right: .5rem;
                    width: calc(33.3% - 1.5rem);
                }
                @include responsive (mobile) {
                    margin-right: 0;
                    width: calc(100%);
                }
                .category-title {
                    background: $nationalGridLightBlue;
                }
                .option {
                    width: 100%;
                    margin-right: 0;
                    &:last-of-type {
                        width: 100%;
                    }
                    @include responsive(tablet) {
                        margin-right: 0;
                    }

                };
                .option .or {
                    padding: 10px;
                    display: block;
                }
            }
            &.half {
                width: 50%;
                @include responsive (mobile) {
                    margin-right: 0;
                    width: calc(100%);
                }
            }
            &.quarter {
                width: 25%;
                @include responsive (mobile) {
                    margin-right: 0;
                    width: calc(100%);
                }
            }
            &.last {
                display:none;
                margin-right: 0;
                width: calc(20%);
                @include responsive (tablet) {
                    display:none;
                }
                .option {
                    width: 100%;
                    &:last-of-type {
                        width: 100%;
                    }
                    @include responsive(tablet) {
                        width: 100%;
                    }
                    &:first-child{
                        .or{
                            @include responsive(tablet) {
                                display: none;
                            }
                        }
                    }
                };
            }
            > ul {
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
            }
            .option {
                background: #fff;
                border-radius: 0.15rem;
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                margin-top: 0.25rem;
                max-width: 693px;
                overflow: visible;
                padding: 1rem 1.5rem;
                position: relative;
                width: calc(50% - 0.33rem);
                @include responsive(mobile) {
                    margin-right: 0;
                    width: 100%;
                }
                &.secondary {
                    .option-title {
                        color: darken($nationalGridGreen, 5);
                    }
                    .blue-title {
                        color: $nationalGridBlue;
                    }
                    .orange-title {
                        color: $nationalGridOrange;
                    }
                    .green-title {
                        color: $nationalGridGreen;
                    }
                    .first, .third {
                        background: #fafafa !important;
                    }

                    .btn {
                        background: $nationalGridOrange;
                    }

                }
                &.tertiary {
                    .option-title {
                        color: $nationalGridOrange;
                    }
                    .blue-title {
                        color: $nationalGridBlue;
                    }
                    .orange-title {
                        color: $nationalGridOrange;
                    }
                    .green-title {
                        color: $nationalGridGreen;
                    }
                    .first, .third {
                        background: #fafafa !important;
                    }
                    .btn {
                        background: $nationalGridOrange;
                    }
                }
                @include responsive(tablet) {
                    margin-right: 0.5rem;
                }

                &:hover {
                    cursor: pointer;
                    background: #fafafa;
                }
                &.nooption {
                    &:hover{
                        cursor:auto;
                    }
                }
                &.opened {
                    .btn {
                        // background-color: $nationalGridGreen;
                        // position: relative;
                        // &:after {
                        //     background-image: url('./../../assets/tick.svg');
                        //     background-position: 99% 99%;
                        //     background-repeat: no-repeat;
                        //     background-size: contain;
                        //     content: '';
                        //     height: 50%;
                        //     position: absolute;
                        //     right: 0;
                        //     top: 49%;
                        //     width: 50%;
                        // }
                    }
                }
                .or {
                    background: $nationalGridLightBlue;
                    border-radius: 100%;
                    color: white;
                    text-transform: lowercase;
                    content: 'or';
                    font-weight: bold;
                    left: -25px;
                    padding: 7px;
                    position: absolute;
                    top: -1.7rem;
                    transform: translateY(-50%);
                    transition: transform 0.5s;
                    z-index: 2;
                    border: 2px solid white;
                }
                &:not(:last-child) {
                    margin-right: 0.5rem;
                    @include responsive(mobile) {
                        margin-right: 0;
                    }
                }
                &:last-child{
                    @include responsive(tablet) {
                        margin-right: 0;
                    }
                }
                .option-title {
                    color: $nationalGridBlue;
                    font-size: 1.1rem;
                    font-weight: 700;
                    .understand {
                        display: block;
                        font-weight: 400;
                    }
                }
                ul {
                    flex-grow: 1;
                    list-style-type: square;
                    margin-top: 0.5rem;
                    padding-left: 1.25rem;
                    li {
                        padding: 0.25rem;
                        line-height: 1.5;
                    }
                }
                .sub-group {
                    flex-grow: 1;
                    margin: 0 -1.5rem;
                    padding: 1rem 1.5rem;
                    &.first, &.third {
                        background: transparentize($nationalGridLightBlue, 0.95);
                    }
                    &.first {
                        min-height: 13.9rem;
                        margin-top: 1rem;
                    }
                    &.second {
                        min-height: 6rem;
                    }
                    &.third {
                        min-height: 11rem;
                        margin-bottom: 0.5rem;
                    }
                    &.fourth {
                        margin-bottom: -1rem;
                    }
                    .sub-title {
                        font-size: 1.1rem;
                        font-weight: 400;
                    }
                    .blue-title {
                        color: $nationalGridBlue;
                    }
                    .orange-title {
                        color: $nationalGridOrange;
                    }
                    .green-title {
                        color: $nationalGridGreen;
                    }
                }
                .two-videos {
                    display: flex;
                    .video {
                        &:first-child {
                            margin-right: 0.5rem;
                        }
                        flex-grow: 1;

                    }
                }
            }
        }
    }
}
.end-of-pipe {
    background: $pipeEndYellow;
    box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
    height: 0.6rem;
    position: absolute;
    right: calc(75vw + 0.9rem);
    top: calc(3vh + 1.5rem + 20vh);
    width: 1.5rem;
    z-index: 99;
}
.grey-curve {
    left: 0;
    width: 100%;
    height: 28vw;
    position: absolute;
    margin-top: 0;
    z-index: 0;
    background: #fff;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
.sub-group {
    overflow: hidden;
}
p {
    font-size: 15px;
}
ul {
    li {
        font-size: 15px;
    }
}
</style>
