<template>
    <div class="captions" v-if="video === 'Intro video'">
        <h2>Video script</h2>
        <p>Welcome to My Retirement Planner designed to help you understand your retirement benefits and options.</p>
        <p>As a member of the National Grid UK Pension  Scheme you have a number of different options for how and when your benefits are paid. During this introductory video we outline some of the key information provided by My Retirement Planner and where you can find details of the financial adviser made available to help you think about your options.</p>
        <p>The purpose of the Scheme is to provide you with an income - a pension - in your retirement, for the rest of your life. Your pension is a Defined Benefit pension, this means it is based upon the number of years you’ve been a member of the Scheme and your salary.</p>
        <p>The Scheme also provides protection for your dependants, as it may provide a lump sum and a pension to your dependants if you were to die before them. Your pension is really important for both you and your family and we’re here to help you better understand the options and the choices you will need to make.</p>
        <p>My Retirement Planner provides an illustration of your benefits and options and there are five videos to help explain the choices you will need to make before you retire.</p>
        <p>These videos cover when you can start receiving your pension and what options you have with this pension, for example to exchange part of your pension for a cash sum.</p>
        <p>They also cover a completely different option - where you are able to give up all of the benefits that you have in the Scheme for a single sum of money that is transferred to a new arrangement of your choice. This is called a transfer value.</p>
        <p>Even if you already have an idea about what you want to do, it’s a good idea to watch at least the first 3 videos. It should only take around 10 minutes in total and you’ll have a better understanding of your benefits and options so you can make the most of your retirement.</p>
        <p>My Retirement Planner can also help you see the income and cash sum you could receive if you choose to receive your benefits from the Scheme now or at a future date. Alternatively it will show you the amount available to you if you transfer your benefits to another pension arrangement of your choice.</p>
        <p>We have calculated illustrative figures based on information readily available.  Please remember we don’t have all of your financial information, such as any pensions from other employers and we don’t know how financial conditions and other assumptions may change over time, so final numbers could well be different particularly if you are using this Planner to project figures into the future. Remember at this stage you don’t need to take any decisions, just find out more about your options.</p>
        <p>Deciding which option to take is complex and My Retirement Planner is designed to help you understand these options, not tell you what to do. The Trustees of the Scheme have therefore decided that they will provide you with access to an independent financial adviser to help you with your decisions.</p>
        <p>The financial advisor has a detailed knowledge of your benefits and the options in the National Grid UK Pension Scheme and operates completely independently of the Scheme. A further video provides details of the specialist financial adviser.</p>
        <p>We cannot cover everything in My Retirement Planner.  For more details of your options in the Scheme, please take a look at the Scheme website or talk to the appointed financial adviser.  We have made every effort to ensure accuracy.  However, the illustrations cannot override the Trust Deed and Rules of the Scheme or any legal requirements.</p>
        <p>You may well have pensions and savings in other arrangements too. We cannot cover those in this Planner but it would be a good idea for you to find out more about your benefits and options in those arrangements too, as they may have an impact on what options you choose for your Scheme benefits. If you do decide to take financial advice, whether from the Scheme-appointed financial adviser or another financial adviser of your choosing, your adviser will ask you about your other pensions and savings. There are plenty of other helpful places where you can find out more about your pensions, so please make sure you take a look at these</p>
        <div class="disclaimer">
            <p>This video series has been prepared for certain members of the National Grid UK Pension Scheme.
            The videos outline retirement benefits and options as defined in the Trust Deed and Rules.
            It is a good idea to watch all of the videos in the series as this may highlight points you are not aware of.
            The videos do not provide financial advice. Please seek advice from an FCA authorised adviser on your unique situation</p>
        </div>
    </div>
    <div class="captions" v-else-if="video.indexOf('When can I receive my pension?') > -1">
        <h2>Video script</h2>
        <p>Your National Grid UK Pension Scheme benefits will pay you a secure income for life. Your pension increases each year to provide you with protection against inflation.</p>
        <p>For most members, your pension is due to come into payment when you reach your 65th birthday. This is known as your “Normal Retirement Age”. However, most members can take their pension before this date.</p>
        <p>Most members can take their pension from their 60th birthday without reduction for early payment.</p>
        <p>In addition many members can also ask the Trustees to start paying their pension earlier than age 60. This is known as early retirement but this option is not available to everyone.</p>
        <p>In most cases your pension can only start before age 55 if you are in ill-health. The Government is increasing the earliest age a pension scheme can normally pay pension from age 55 to age 57 from 6 April 2028 but this change is not expected to impact the earliest age your pension can be payable from this Scheme.</p>
        <p>If your pension starts before age 60, it will normally be reduced, as it is expected that it will be in payment for longer.</p>
        <p>Just as you can ask for your pension to start before age 60, you can also take your pension later. If your pension starts after age 60, it will normally be increased, as it is expected that it will be in payment for less time.</p>
        <p>Under the rules of the Scheme, if you remain a contributing member you will continue to build up benefits after age 65 but you cannot postpone payment of your pension beyond your 75th birthday.</p>
        <p>Different terms may apply if you earned all your benefits before 21 August 1986 or left the employer because you were made redundant, so if this applies it will be reflected in your illustrative pension figures provided on this website.</p>
        <p>Whatever age you decide to retire you will have the option to give up some of your pension for a lump sum when your pension starts. This is explained in more detail in video 3.</p>
        <p>With your benefits, there are also some protections for your dependants if you were to die. However, what is payable from the Scheme depends on whether payment of your pension has started.</p>
        <p>If you were to die after payment of your pension has started, the Trustees will pay a pension to your spouse or civil partner, broadly equal to around two-thirds of the pension that was being paid to you when you died.</p>

        <p>If your death is within five years’ of your pension starting there will also be a lump sum equal to the remainder of the first five years of payments that would have been payable to you - this is known as a 5 year guarantee.</p>
        <p>If there is no spouse or civil partner, the pension may be paid to someone else, at the Trustees’ discretion, as long as there was financial dependency or interdependency at the date of your death.</p>
        <p>A bereavement grant of £1,000 will also be payable to help towards immediate funeral expenses if you die after your pension has started.</p>
        <p>It is important that you let the Trustees know who your financial dependants are. This can be done by completing a nomination of dependant form. You can also complete a letter of intent to indicate who you would like any death lump sum to be paid to.</p>
        <p>If you want to find out more about your retirement pension including early or late retirement then please see your illustrative figures on this website.</p>
        <div class="disclaimer">
            <p>This video series has been prepared for certain members of the National Grid UK Pension Scheme. The videos outline retirement benefits and options as defined in the Trust Deed and Rules. It is a good idea to watch all of the videos in the series as this may highlight points you are not aware of. The videos do not provide financial advice. Please seek advice from an FCA authorised adviser on your unique situation</p>
        </div>
    </div>
    <div class="captions" v-else-if="video === 'Other options within the scheme'">
        <h2>Video script</h2>
        <p>One option that you have as you approach retirement is to give up some of your pension for a lump sum, payable at the point your pension comes into payment.</p>
        <p>The most you can give up for a lump sum in normal circumstances is around 25% of your pension.</p>
        <p>Under current tax rules the lump sum is tax-free. The lump sum and the amount of pension you have to swap to receive that lump sum will vary over time.</p>
        <p>Giving up some of your pension for a lump sum does not affect the pension that would be payable to your dependant on your death.</p>
        <p>If your death is within five years’ of your pension starting there will be a lump sum equal to the remainder of the first five years’ payments that would have been payable to you. So if you are getting a lower pension because you gave up some of it for a lump sum, the amount payable under the five year guarantee will be smaller too.</p>
        <p>If you want to find out more about the option of a lump sum on retirement then please see your illustrative figures on this website.</p>
        <p>If you have paid Money Purchase Additional Voluntary Contributions, also known as AVCs, into the National Grid Scheme, this will normally be used in the first instance to provide any tax-free cash sum you request.</p>
        <p>In addition you have the option to receive a higher pension between your date of retirement and State Pension Age. This is known as the Bridge the Gap option. For further details of this option please read the Bridge the Gap leaflet found on this website.</p>
        <div class="disclaimer">
            <p>This video series has been prepared for certain members of the National Grid UK Pension Scheme.
            The videos outline retirement benefits and options as defined in the Trust Deed and Rules.
            It is a good idea to watch all of the videos in the series as this may highlight points you are not aware of.
            The videos do not provide financial advice. Please seek advice from an FCA authorised adviser on your unique situation</p>
        </div>
    </div>
    <div class="captions" v-else-if="video.indexOf('Intro') > -1">
        <h2>Video script</h2>
        <p>In this video we look at the options for transferring your National Grid UK Pension Scheme benefits to another pension arrangement.</p>
        <p>Changes to legislation means there is flexibility over how you can access your Scheme benefits. As a result more people are considering transferring their benefits out of the Scheme, and there will be pros and cons to this.  </p>
        <p>For example, transferring your Scheme pension might be useful for those wishing to enjoy a more flexible income during retirement.</p>
        <p>Others, who might be concerned about running out of money in the years ahead and are happy with the security that the benefits from a defined benefit pension provides for them and their dependants, might prefer staying in the Scheme.</p>
        <p>It is important to understand all your options and what they might mean for you. Unfortunately we cannot cover everything in these videos but we will provide details of where you can find out more later in this video.  </p>
        <p>Knowing if a transfer value is right for you will very much depend on your personal circumstances. After taking advice many members will find that staying in the Scheme is likely to be the best choice for them.</p>
        <p>A transfer value is the option to move the value of all of your benefits that you have in the Scheme, including your benefits payable on death, to another pension arrangement.</p>
        <p>This is paid as a single sum of money that is transferred to another pension arrangement of your choice. If you take a transfer value you cannot change your mind later and transfer back into the Scheme. Likewise, under current law, once your pension in the Scheme starts to be paid, you can no longer take a transfer value.</p>
        <p>A transfer value is calculated by the Trustees. The law requires that it reflects the expected cost of providing your benefits within the Scheme. This could be quite different from the cost of buying retirement income from another pension arrangement.</p>
        <p>The Trustees take into account certain factors such as how long they expect members to live, expectations of future inflation and around future investment returns. Transfer values can therefore change, sometimes significantly, as investment markets move or the Trustees’ expectations change.</p>
        <p>If you take a transfer value the benefits you may receive will depend on what options your new pension arrangement offers you. There is quite a lot of choice in the market and some of these options are introduced in the next videos.</p>
        <p>Deciding whether to take a transfer value from a defined benefit pension scheme like the National Grid UK Pension Scheme is a significant decision. In recognition of this, the Government require an individual seeking to take a transfer of £30,000 or more to obtain financial advice.</p>
        <p>You also need to be aware of the risk of scams. The Pensions Regulator is concerned thousands of people have lost their life savings. Their website has a ten step checklist on how to protect yourself from scams.</p>
        <p>If you are thinking about taking a transfer value, a financial adviser can help you consider a number of different factors regarding your personal circumstances, your assets and your commitments; and the benefits and options provided by the Scheme versus other options.</p>
        <p>If you do want to take a transfer value, the process is complex and can take many months. So take this into consideration when you are planning for your retirement.</p>
        <p>We hope you have found these videos useful. There are plenty of places to find out more than we can cover in our short videos, so make sure you take a look at these too. Thank you for watching.</p>
        <div class="disclaimer">
            <p>This video series has been prepared for certain members of the National Grid UK Pension Scheme.
            The videos outline retirement benefits and options as defined in the Trust Deed and Rules.
            It is a good idea to watch all of the videos in the series as this may highlight points you are not aware of.
            The videos do not provide financial advice. Please seek advice from an FCA authorised adviser on your unique situation</p>
        </div>
    </div>
    <div class="captions" v-else-if="video.indexOf('insurer') > -1">
        <h2>Video script</h2>
        <p>In this video we look at the option for transferring out your National Grid UK Pension Scheme benefits so that you can receive them as a regular retirement income for life but from an insurance company - also known as an annuity.</p>
        <p>Under this option you can normally take 25% of your transfer as a tax-free cash lump sum. You then use the rest to buy an alternative income for life.</p>
        <p>There are lots of options and choices when you come to buy this income. Some people prefer a fixed income which may start at a higher level but will remain at the same level with your buying power reducing over time as prices rise.</p>
        <p>Others prefer an increasing income product which pays a much lower income to start with, but eventually overtakes fixed income products and will protect you from rising prices over time. An increasing income product is a bit like your National Grid UK Pension Scheme benefits but without the dependants benefits (unless you buy these as well).</p>
        <p>The other main choice is whether to buy a single or joint guaranteed income.</p>
        <p>A single guaranteed income provides you with a regular income for the rest of your life. When you die it stops and therefore is normally only suitable if you have no financial dependants.  </p>
        <p>A joint guaranteed income provides an income to you during your lifetime and then to your partner or other nominated beneficiary for the rest of their life after you die. This is like your National Grid UK Pension Scheme benefits but you can also choose how much your partner or beneficiary will receive. This can be the same income as you receive, or half or two-thirds.</p>
        <p>You can choose to have your income guaranteed to continue after your death for a period of between 5 and 10 years. This is known as the guarantee period.</p>
        <p>If you want to find out more about giving up your benefits in the scheme and taking a transfer value to buy an alternative income elsewhere then please see your illustrative figures on this website.</p>
        <p>There are also other guaranteed income options available for example if you are in ill health or have certain medical conditions you may be able to buy a higher income or some people prefer to buy a guaranteed income for a fixed term rather than for life.</p>
        <p>If you are interested in exploring this option then we recommend you talk to a financial adviser.</p>
        <p>In video 4 where we gave an introduction to the transfer value option we highlighted that deciding whether to take a transfer value from the Scheme is a significant decision.</p>
        <p>If you decide to go ahead with this option and if your transfer value is over £30,000, the Trustees are required to see evidence that you have taken financial advice. The Trustees are providing you with access to an independent financial adviser and you may choose to use this adviser if you wish, or you may prefer to engage your own financial adviser.</p>
        <p>We hope you have found these videos useful. There are plenty of places to find out more than we can cover in our short videos, so make sure you take a look at these too. Thank you for watching.</p>
        <div class="disclaimer">
            <p>This video series has been prepared for certain members of the National Grid UK Pension Scheme.
            The videos outline retirement benefits and options as defined in the Trust Deed and Rules.
            It is a good idea to watch all of the videos in the series as this may highlight points you are not aware of.
            The videos do not provide financial advice. Please seek advice from an FCA authorised adviser on your unique situation</p>
        </div>
    </div>
    <div class="captions" v-else-if="video.indexOf('Flexible') > -1">
        <h2>Video script</h2>
        <p>In this video we look at the option for transferring out your benefits so that you can receive them from another pension arrangement and take a flexible income.</p>
        <p>If you transfer your National Grid UK Pension Scheme benefits to another pension arrangement and keep the money invested, you may be able to take income from that arrangement from age 55. You may also be able to vary the amount of income you take each year. </p>
        <p>Under this option you can normally take 25% of your transfer as a tax-free cash lump sum. Once you have taken your tax-free lump sum you can start taking the income right away, or wait until a later date.</p>
        <p>This income isn’t guaranteed so there is a risk your money might run out before you or your dependants die.  </p>
        <p>This will depend on how much income you take, how long you live and the investment returns you can get on your money.  On average people aged 55 today will live to their mid-to-late 80s.  In addition, increases in the cost of living may mean you have to take out more income than expected.</p>
        <p>You will need to choose funds to invest in. Investment choice is key - you will need to keep your funds under regular review to ensure they continue to meet your long-term retirement income needs. Many providers offer a default fund which reduces the investment choices you need to make.</p>
        <p>As with many investments, the value of your pension pot can go up or down. Charges will apply and can affect the value of your investments and the money you receive.</p>
        <p>If you die before age 75 anything remaining in your fund may pass to your nominated beneficiary as an income-tax-free lump sum.</p>
        <p>If you die age 75 or above anything remaining in your fund can pass to your beneficiary either as a lump sum or income but it will be taxed at the beneficiary’s income tax rate.</p>
        <p>You can alternatively take your whole amount as cash in one go if you wish. However, three-quarters of the amount you withdraw is taxable income, so there’s a strong chance your tax rate would go up. Therefore, for most members taking the whole amount as cash is not attractive.</p>
        <p>Taking a flexible income option may result in a restriction on any future pension contributions you can make (or have made for you). If you are contributing to another pension arrangement then you should consider this.</p>
        <p>If you want to find out more about giving up your benefits in the Scheme and taking a transfer value to take a flexible income option then please see your illustrative figures on this website.</p>
        <p>If you are interested in exploring this option then we recommend you talk to a financial adviser.</p>
        <p>In video 4 where we gave an introduction to the transfer value option we highlighted that deciding whether to take a transfer value from the Scheme is a significant decision.</p>
        <p>If you decide to go ahead with this option and if your transfer value is over £30,000, the Trustees are required to see evidence that you have taken financial advice.  The Trustees are providing you with access to an independent financial adviser and you may choose to use this adviser if you wish, or you may prefer to engage your own financial adviser.</p>
        <p>We hope you have found these videos useful. There are plenty of places to find out more than we can cover in our short videos, so make sure you take a look at these too. Thank you for watching.</p>
        <div class="disclaimer">
            <p>This video series has been prepared for certain members of the National Grid UK Pension Scheme.
            The videos outline retirement benefits and options as defined in the Trust Deed and Rules.
            It is a good idea to watch all of the videos in the series as this may highlight points you are not aware of.
            The videos do not provide financial advice. Please seek advice from an FCA authorised adviser on your unique situation</p>
        </div>
    </div>
    <div class="captions" v-else-if="video === 'WPS'">
        <h2>Video script</h2>
        <h3>Who are we? </h3>
        <p>We are a firm of independent financial advisors. We help people like yourselves make informed decisions about your finances. Our ethos is to provide impartial advice to help you build a better understanding of your financial situation, and to help you develop a plan to achieve your personal goals. For your peace of mind, our advice is directly regulated by the Financial Conduct Authority; often referred to as the FCA. What that means, is that we abide by a set of rules which seek to ensure we always represent your best interests.</p>
        <h3>Why are we speaking to you today?</h3>
        <p>Your retirement is one of the most important milestones in your life, and most people we speak to need some help to make the right choices for them and their loved ones. You want to be able to trust someone to provide you with this help, and often the bigger the financial decision the more important this is. The trustees of the national grid UK Pension Scheme recognise this and have appointed us, following a full and thorough selection process, to help you with your plans for retirement if you need it. We are being paid to provide the right advice to assist you in making the right decisions for you. There are no hidden costs, we take no commission and we don’t have any products to sell. </p>
        <h3>If you devide to take advice from us, what can you expect?</h3>
        <p>We want to help you to plan your retirement and find the best outcome for you when you come to retire. It’s our job to listen and learn all about you and from this understand your values, your objectives, your needs and your wants, for you and your loved ones. In doing this, we will learn all about you, how you made decisions in the past, when you made them and why you made them. We will want to learn how you felt not only when those decisions worked out, but when they didn’t. The reason we do this is so that before we set out your plan and share it with you, we make sure we have properly understood who you are and what you want to do. It is really important that any plan we come up with belongs to you and is not someone else’s idea of what you should do, after all it is your retirement. </p>
        <h3>When should you make your plans?</h3>
        <p>For some people, they make plans at the time they decide to retire, and that’s the right time for them because its only then that they are clear in their mind what they want to do. For others, they like to have a plan in place as early as possible. You have been given access to a lot of information and support. Because the Trustees only pay for the advice once, you need to decide when is the right time to take advice. If you are not sure just ask us. </p>
        <h3>Who pays for our services?</h3>
        <p>The trustees of the scheme will normally pay for one piece of advice, and normally only once you’ve reached age 54. If you find you need more than one piece of advice or you would like to take advice earlier then you will need to pay for this. However, the Trustees have agreed a price which means you pay a fee in pounds and pence, and not a percentage of your transfer value. This is typically cheaper than what you would get it if you met with a financial advisor who did not know about your scheme or have access directly to information from your scheme. Whether you choose to take advice from us or your own financial advisor, remember if somebody tells you that you can have something for nothing or if something seems too good to be true then it usually is. In certain situations, for example, if your needs are particularly complex you may be asked to pay an additional fee and we will explain and obtain an agreement with you before any further work is carried out.</p>
        <h3>So can I speak to you and find out if I need help?</h3>
        <p>You can call us to find out if it’s the right time for you. If having spoken to us you think now is not the right time or you are comfortable making your own decisions, then that’s fine. However, if you like what you hear and what you see then we will be glad to help. After all, we want to help you make better informed decisions about your retirement. For more information, please follow the steps set out within this retirement planner. </p>
    </div>
</template>

<script>
export default {
    props: {
        video: String
    }
}
</script>

<style scoped lang="scss">
    .captions {
        color: #fff;
        background: #2d2d2d;
        text-align: center;
        height: 100%;
        overflow: auto;
        max-height: 80vh;
        width: 100%;
        padding: 1rem;
        h2 {
            font-size: 1.4rem;
            padding: 1rem;
        }
        h3 {
            font-size: 1.2rem;
            margin-top: 1.5rem;
        }
        p {
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;
            font-size: 1.1rem;
            text-align: left;
            margin-bottom: 1.5rem;
        }

        .disclaimer {
            margin-top: 3rem;
            font-size: 0.9rem;
            font-style: italic;
        }
    }
    @media(max-width: 700px) {
        .captions {
            margin-top: 4rem;
        }
    }
</style>