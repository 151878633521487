<template>        
<div class="input-container">
  <input class="validate" v-if="prop.type === 'text'" required="required" type="text" v-model="propModel"/>
  <textarea v-if="prop.type === 'textarea'" rows="5" type="text" v-model="propModel"></textarea><span v-if="prop.type === 'money'">£</span>
  <input v-if="prop.type === 'number' || prop.type === 'money'" :class="{ money: prop.type === 'money' }" type="number" v-model="propModel"/>
  <date-picker v-if="prop.type === 'date'" v-model="propModel"></date-picker>
  <div class="yes-no" v-if="prop.type === 'yesNo'">
    <div class="option">
      <input type="radio" :name="id" :id="id + 'y'" :value="true" v-model="propModel"/>
      <label :for="id + 'y'">Yes</label>
    </div>
    <div class="option">
      <input type="radio" :name="id" :id="id + 'n'" :value="false" v-model="propModel"/>
      <label :for="id + 'n'">No</label>
    </div>
  </div>
</div>
</template>
<script>
import Guid from '../../mixins/guid.js';
import datePicker from '../common/datePicker';

export default {
    name: 'table-input',
    props: [ 'value', 'prop' ],
    components: { datePicker },
    mixins: [Guid],
    data () {
        return {
            id: ''
        };
    },
    mounted () {
        this.id = this.generateGuid();
    },
    computed: {
        propModel: {
            get () { return this.value; },
            set (value) { this.$emit('input', value); },
        }
    }
};
</script>
<style lang="scss">
@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';
.yes-no {
    overflow: hidden;
    text-align: center;
    width: 100%;
    padding: 0.5rem 0;
    .option {
        display: inline-block;
        margin: 0.5rem;
    }
    input {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        left: -9999px;
        &:not(:checked) ~ label {
            background: #ccc;
            box-shadow: none;
            transform: scale(1);
            z-index: 0;
        }
    }
    label {
        display: block;
        margin: 0;
        padding: 1rem 2rem;
        min-width: auto;
        width: 100%;
        background: $highlightColour;
        text-align: center;
        color: #fff;
        box-shadow: 1px 1px 2px rgba(0,0,0,.2);
        transform: scale(1.05);
        position: relative;
        transition: transform .25s;
        z-index: 1;
        &:hover {
            cursor: pointer;
        }
    }
}

.input-container {
    display: flex;
    align-items: center;
    width: 100%;
    input{
        border-bottom: 2px solid #eee;
        &:focus{
            box-shadow: none;
            border-bottom: 2px solid lightgray;
        }
    }
    span {
        margin-right: 0.5rem;
        color: #666;
    }
}

</style>