import Vue from 'vue';
Vue.directive('focus', {
    bind: function (el, binding, component) {
        el.addEventListener('focus', () => {
            component.context[binding.expression] = true;
            eventFire(el, 'click');
            // dispatching click event, the focus capture prevents it firing
            function eventFire (el, etype) {
                if (el.fireEvent) {
                    el.fireEvent('on' + etype);
                } else {
                    var evObj = document.createEvent('Events');
                    evObj.initEvent(etype, true, false);
                    el.dispatchEvent(evObj);
                }
            }
        });
    },
});

