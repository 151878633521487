import html2pdf from 'html2pdf.js';

String.prototype.base64ToBlob = function (mime) {
    var bstr = atob(this);
    var ab = bstr.s2ab();
    return new Blob([ab], { type: mime });
};

String.prototype.s2ab = function () {
    var buf = new ArrayBuffer(this.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== this.length; ++i) view[i] = this.charCodeAt(i) & 0xFF;
    return buf;
};

const state = {
    show: false
};

const getters = {
};

const actions = {
    print (context, data) {
        const printOptions = {
            margin: 0.1,
            filename: 'retirment-planner.pdf',
            image: {
                type: 'jpeg',
                quality: 0.98,
            },
            html2canvas: {
                dpi: 192,
                scale: 2,
                letterRendering: true,
                useCORS: true,
                onrendered (canvas) {
                    const ctx = canvas.getContext('2d');
                    ctx.webkitImageSmoothingEnabled = true;
                    ctx.mozImageSmoothingEnabled = true;
                    ctx.imageSmoothingEnabled = true;
                },
            },
            jsPDF: {
                unit: 'in',
                format: 'letter',
                orientation: 'portrait',
            },
        };
        const refdoc = data;
        html2pdf().from(refdoc).set(printOptions).save()
            .then(() => {
                this.$store.commit('print', false);
            });
        // return printApi.postPrint(data)
        //     .then(response => {
        //         const mime = 'application/pdf';
        //         var blob = response.data.base64ToBlob(mime);

        //         // IE doesn't allow using a blob object directly as link href
        //         // instead it is necessary to use msSaveOrOpenBlob
        //         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //             window.navigator.msSaveOrOpenBlob(blob);
        //             return;
        //         }

        //         fileSave.saveAs(blob, "My Retirement Planner");
        //     })
        //     .catch(error => {
        //         // do something
        //     });
    }
};

const mutations = {
    print (state, data) {
        state.show = data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
