<template>
<div class="intro">
    <div class="container">
        <div class="name"> Hello<span v-if="name!=''"> {{name | nameFormat}}</span>,</div>
        <p>
           Welcome to My Retirement Planner. Here you can find information about your retirement benefits from the Scheme and the different options you have.
        </p>
        <p class="bold" v-if="user.type == 'full'">
            Don’t worry, by exploring My Retirement Planner and reviewing the options available to you, you are not committing yourself to any given option. My Retirement Planner is for information only, based on the data we hold for you, current law and regulatory guidance and the Scheme's Trust Deed and Rules.
        </p>
        <p class="bold"  v-else-if="user.type == 'age_restricted'">
            For most members, when you’re within a year of your earliest Scheme retirement age (usually age 54), my Retirement Planner will show your Scheme figures and let you customise aspects to show a range of different illustrations and outcomes.
        </p>
        <p class="bold"  v-else>
            Unfortunately due to your specific circumstances some aspects of the My Retirement Planner are not currently available to you.  However, there is still lots of useful information for you to view.
        </p>
         <img class="sunshine" src="../../assets/sunshine.png" />
    </div>
    <div class="white-curve" />
</div>
</template>

<script>
export default {
    name: 'intro',
    props: {
    },
    computed: {
        user () {
            return this.$store.getters.getUser;
        },
        name () {

            let name = this.user.forenames;

            if (typeof name !== 'string') return '';
            let firstN = name.split(" ")[0];

            if (firstN.length > 2) {
                return firstN;
            }

            return '';
        },

        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
    }
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
.container {
  @include responsive(tablet) {
    padding: 1rem 2rem 1rem 2rem;
  }
}
.intro {
    position: relative;
    height:150px;
    .name {
        color: $highlightColour;
        font-size: 2rem;
        font-weight: 400;
    }
    p {
        background: transparentize($nationalGridBlue, 0.3);
        max-width: 500px;
        color: white;
        font-size: 1.2rem;
    }
    .bold {
        font-weight: bold;
    }
    .container {
        position: relative;
        z-index: 9;
        .sunshine {
            left: 50%;
            position: absolute;
            top: 20px;
            @include responsive (mobile) {
                display: none;
            }
        }
    }
    padding-bottom: 0;
}

.white-curve {
    left: 0rem;
    width: 100%;
    height: 28vw;
    position: absolute;
    margin-top: -10vw;
    z-index: 0;
    background: $nationalGridBlue;
    background-image: url('./../../assets/white-curve.png');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: 168vh white solid;
    box-sizing: content-box;
    box-shadow: inset 0px -3px 0px white;
    &:after {
        content: '';
        background: white;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0px;
    }
}
</style>
