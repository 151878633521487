<template>
<modal title="Feedback" @close="$emit('close')">
    <template slot="intro">
        <p>We are interested in your feedback on this site and would love to know what you found helpful, or whether you think anything could be improved.</p>
    </template>
    <template slot="content">
        <div class="content" v-if="!submitted">
            <div class="rating">
                <label>Site rating</label>
                <star-rating v-model="rating" :showRating="false"></star-rating>
            </div>
            <div class="feedback">
                <label :class="{ required: rating && rating <= 3 }">Please provide further details below</label>
                <textarea v-model="feedback" rows="6"/>
            </div>
            <div class="submit" :class="{ disabled: !submitEnabled }" @click="submit">Submit</div>
        </div>
        <div class="content" v-else>
            <p style="margin-top: 33vh; text-align: center">Thank you for your feedback</p>
            <div class="submit" @click="$emit('submit')">Close</div>
        </div>
    </template>
</modal>
</template>

<script>
import Modal from './common/Modal';
import constants from './../constants.js';
import StarRating from 'vue-star-rating'


export default {
    name: 'Annuity',
    components: { StarRating, Modal },
    data () {
        return {
            showQuestions: false,
            mounted: false,
            rating: 0,
            feedback: '',
            submitted: false
        };
    },
    mounted () {
        this.mounted = true;
    },
    created () {
    },
    methods: {
        submit () {
            this.$store.dispatch('postAnalytics', { measure: constants.SiteFeedback, value: JSON.stringify({ rating: this.rating, feedback: this.feedback})});
            this.submitted = true;
        }
    },
    computed: {
        submitEnabled () {
            return this.rating > 3 || (this.rating && this.feedback.length);
        },
        user () {
            return this.$store.getters.getUser;
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
    },
    watch: {
        questionNo () {
            this.$store.dispatch('postAnalytics', { measure: constants.Modal_SchemePension_Progress, value: this.questionNo });
        }
    }
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';

.rating {
    margin-top: 20vh;
    @include responsive (mobile) {
       margin-top: 30vh;
    }
    position: relative;
    text-align: center;
    ::v-deep .vue-star-rating {
        justify-content: center;
    }
}

.content {
    max-height: 80vh;
    overflow: auto;
}
.feedback {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding: 4rem;
}

label {
    margin-bottom: 1rem;
    display: block;
    &.required {
        &:after {
            content: ('(required)');
            color: red;
            display: block;
            margin-top: 0.5rem;
        }
    }
}

.submit {
    background: $nationalGridPink;
    display: block;
    padding: 0.5rem 2rem;
    color: white;
    position: absolute;
    right: 1.5rem;
    bottom: 1rem;
    border-radius: 3rem;
    &:hover {
        cursor: pointer;
        background: lighten($highlightColour, 5);
    }
    @include responsive (mobile) {
        transform: none;
        right: 1rem;
        bottom: 1.5rem;
        display: inline-block;
        margin-bottom: 2rem;
        font-size: 1rem;
    }
    &.disabled {
        background: #aaa;
        color: #fff;
        pointer-events: none;
    }
}
</style>