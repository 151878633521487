import hello from 'hellojs';

import { APPLICATIONID, SIGNINSIGNUPPOLICYNAME, REDIRECTURI, TENANTNAME, RESPONSETYPE, HELLOJSLOGINNAME, SCOPE } from './b2cConfig.js';

export default {
    forgottenPassword () {
        hello('B2C_1_Pwd_Reset_MRP_NG');
    },
    init () {
        hello.init({
            b2clogin: {
                name: 'Azure AD B2C Login',
                oauth: {
                    version: 2,
                    auth: 'https://lcpauth.b2clogin.com/tfp/' + TENANTNAME + '/' + SIGNINSIGNUPPOLICYNAME + '/oauth2/v2.0/authorize',
                    grant: 'https://lcpauth.b2clogin.com/tfp/' + TENANTNAME + '/' + SIGNINSIGNUPPOLICYNAME + '/oauth2/v2.0/token'
                },
                refresh: true,
                scope_delim: ' ',
                // Don't even try submitting via form.
                // This means no POST operations in <=IE9
                form: false
            }
        });

        hello.init({ b2clogin: APPLICATIONID }, {
            redirect_uri: REDIRECTURI,
            response_type: RESPONSETYPE,
            scope: 'openid ' + SCOPE,
            display: 'page'
        });
    },
    signIn () {
        hello(HELLOJSLOGINNAME)
            .login(
                {page_uri: REDIRECTURI}
            )
            .then(function () {
            }, function (e) {
                alert('Signin error: ' + e.error.message);
            });
    },
    signOut () {
        let authResponse = localStorage.getItem('hello');
        if (authResponse && authResponse !== '{}') {
            authResponse = JSON.parse(authResponse).b2clogin;
            let idToken = authResponse.access_token;
            // get id_token from auth response
            // clearing local storage session
            hello.utils.store(HELLOJSLOGINNAME, null);

            // redirecting to Azure B2C logout URI
            window.location = 'https://lcpauth.b2clogin.com/' + TENANTNAME + '/oauth2/v2.0/logout?p=' + SIGNINSIGNUPPOLICYNAME + '&id_token_hint=' +
                idToken + '&post_logout_redirect_uri=' + REDIRECTURI;
        }
    },
    online (network) {
        var session = hello(network).getAuthResponse(network);
        var currentTime = (new Date()).getTime() / 1000;
        return session && session.access_token && session.expires > currentTime;
    },
    log (s) {
        if (typeof s.error !== 'undefined' && s.error !== null) {
            if (s.error.code === 'blocked') {   // silentrenew(display: none) in case of expired token returns X-frame Options as DENY error
                alert('There was an error in silent renewing the token. Please login again');
                return;
            }
        }
    }
};
