<template>
<div class="questions container" :class="{ 'last-question':  questionNo === questions.length - 1 }">
  <div class="question-no">{{ questionNo + 1 }} / {{ questions.length }}</div>
  <div class="question-offset" :style="{ 'top': -questionNo * 100 + '%' }">
    <slot></slot>
    <div class="previous" @click="previousQuestion" v-if="questionNo">Previous</div>
    <div class="skip" @click="skipAnimation" style="opacity: 0; point-events: none">Skip</div>
    <div class="next" @click="nextQuestion" v-if="!hideDoneButton || (questionNo < questions.length - 1 && !hideNextButton)">
        {{ isNotLastQuestion ? 'Next' : 'Done' }}
    </div>
  </div>
</div>
</template>

<script>
import sortBy from 'lodash/sortBy';
export default {
    name: 'QuestionsFlow',
    props: ['hideDoneButton', 'hideNextButton'],
    data () {
        return {
            questions: [],
            questionNo: 0,
            currentQuestion: null,
            direction: 'forward'
        };
    },
    activated () {
        this.questions = [];
        this.questionNo = 0;
    },
    mounted () {
        this.questionNo = 0;
        if (this.questions.length) {
            this.currentQuestion = this.questions[0];
            this.currentQuestion.active = true;
            this.$emit('questionChanged', this.currentQuestion.title);
        }
    },
    computed: {
        isNotLastQuestion () {
            return this.questionNo < this.questions.length - 1;
        }
    },
    methods: {
        nextQuestion () {
            this.direction = 'forwards';
            if (this.questionNo + 1 < this.questions.length) {
                this.questionNo++;
                this.questionChanged(this.questions[this.questionNo]);
            } else {
                this.$emit('complete');
            }
        },
        skipAnimation () {
            if (this.currentQuestion.timeline) {
                this.currentQuestion.timeline.progress(1, false);
            }
        },
        previousQuestion () {
            if (this.currentQuestion.timeline) {
                this.currentQuestion.timeline.progress(1, false);
            }
            this.direction = 'backwards';
            this.questionNo--;
            this.questionChanged(this.questions[this.questionNo]);
        },
        gotoQuestion (questionNo) {
            if (this.questionNo > questionNo) {
                this.direction = 'backwards';
            } else {
                this.direction = 'forwards';
            }
            this.questionNo = questionNo;
            this.questionChanged(this.questions[this.questionNo]);
        },
        gotoQuestionNoMovement (questionNo) {
            this.questionNo = questionNo;
            this.questionChanged(this.questions[this.questionNo]);
        },
        questionChanged (question) {
            this.currentQuestion = question;
            for (var i in this.questions) {
                this.questions[i].active = false;
            }
            question.active = true;
            this.$emit('questionChanged', question.title);
        },
        addQuestion (question) {
            this.questions.push(question);
            this.questions = sortBy(this.questions, a => a.order);
            if (this.questions.length === 1) {
                question.active = true;
                question.firstQuestion = true;
            }
            for (var i in this.questions) {
                this.questions[i].lastQuestion = false;
            }
            question.lastQuestion = true;
            if (question.active) {
                this.currentQuestion = question;
                this.$emit('questionChanged', question.title);
            }
        },
        removeQuestion (question) {
            var questionIndex = this.questions.indexOf(question);
            this.questions.splice(questionIndex, 1);
            this.questions = sortBy(this.questions, a => a.order);

        }
    }
};
</script>


<style scoped lang="scss">
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
.questions {
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;
    margin-bottom: 2rem;
    animation: fadeIn 0.5s;
}
@keyframes fadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
}

.lineFull {
    left: calc(50% - 1.5px);
    height: 98vh;
    top:2vh;
    bottom:2vh;
    width: 3px;
    border-left: 4px solid lighten($lightPipeYellow, 5);
    border-right: 4px solid lighten($pipeYellow, 5);
    position: absolute;

}
.questions.container {
    position: relative;
    height: 100%;
}
.question-no {
    position: absolute;
    right: 2rem;
    background: $nationalGridBlue;
    border-radius: 100%;
    height: 4rem;
    width: 4rem;
    font-size: 1.1rem;
    line-height: 3.4rem;
    text-align: center;
    border: 4px solid $nationalGridLightBlue;
    color: white;
    @include responsive (mobile) {
        display: none;
    }
}

.next, .previous, .skip {
    background: $nationalGridPink;
    display: block;
    padding: 0.5rem 2rem;
    color: white;
    position: absolute;
    right: 1.5rem;
    bottom: 1rem;
    border-radius: 3rem;
    &:hover {
        cursor: pointer;
        background: lighten($highlightColour, 5);
    }
    @include responsive (mobile) {
        transform: none;
        right: 1rem;
        bottom: 1.5rem;
        display: inline-block;
        margin-bottom: 2rem;
        font-size: 1rem;
    }
}

.previous {
    right: auto;
    left: 1.5rem;
    @include responsive (mobile) {
        right: auto;
        left: 1rem;
    }
}
</style>
