import axios from 'axios';
export default {
    getScheme (scheme, success, fail) {
        axios.get('/api/scheme/' + scheme).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    watchedIntroVideo () {
        return axios.put('/api/scheme/');
    },
    requestAdvice () {
        return axios.post('/api/wpsa');
    },
    getCanRequestAdvice () {
        return axios.get('/api/wpsa');
    }
};