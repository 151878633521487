import axios from 'axios';
export default {
    getUser (id, scheme, success, fail) {
        axios.get('/api/users/'+ id + '/' + scheme).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getAllUsers (success, fail) {
        axios.get('/api/users/').then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });  
    },
    getBulk () {
        return axios.get('/api/userbulk/');
    },
    login (username, password) {
        return axios.post('/api/submitauth1', { username, password });
    },
    submitSecurityAnswer (username, answer, id) {
        return axios.post('/api/submitauth2', { username, text: answer, id });
    }
};