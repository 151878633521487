<template>
<div class="intro-video">
    <div class="container">
        <p>
            Welcome to My Retirement Planner designed to help you understand your retirement benefits and options.
        </p>
        <p>
            As a member of the Scheme you have a number of different options for how and when your benefits are paid.
            During this introductory video we outline some of the key information provided by My Retirement Planner and where
            you can find details of the financial adviser made available to help you think about your options.
        </p>
        <div class="video" title="Watch video"  :class="{ watched: getValue('introVideoWatched') }" @click="$emit('watchVideo', 'Intro video')">
            <img src="../../assets/0-your-pension-thumbnail.png" />
        </div>
        <div>
            <a class="btn small introvideo" v-if="!getValue('showIntro') && !getValue('introVideoWatched')"
                @click="$emit('skipVideo')" :class="{ greyed: !getValue('introVideoWatched') }">
                Continue
            </a>
            <a class="btn small introvideo" v-if="!getValue('showIntro') && getValue('introVideoWatched')"
                @click="$emit('continue')" :class="{ greyed: !getValue('introVideoWatched') }">
                Continue
            </a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'intro-video',
    data () {
        return {
        };
    },
    methods: {
        getValue (prop) {
            return this.$store.getters.getValue(prop);
        },
        setValue (prop, value) {
            this.$store.dispatch("setValue", { prop, value });
        },
    }
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
.container {
    @include responsive(tablet) {
        padding: 1rem 2rem 1rem 2rem;
    }
}
.intro-video {
    background: $nationalGridBlue;
    min-height: calc(100vh - 7.15rem);
    overflow: hidden;
    padding-top: 2.5rem;
    position: relative;
    text-align: center;
    width: 100%;
    p {
        text-align: left;
        color: white;
        font-size: 1.2rem;
        max-width: 50vw;
        @include responsive(mobile) {
            max-width: 90vw;
        }
        &:first-child {
            font-size: 1.75rem;
            color: $highlightColour;
        }
        &:last-of-type {
            padding-bottom: 2rem;
        }
    }
    @keyframes dropIn {
        from {
            opacity: 0;
            transform: translateY(-50%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .video {
        animation-fill-mode: backwards;
        animation: dropIn 1s;
        display: inline-block;
        height: auto;
        max-height: 50vw;
        position: relative;
        margin-top: 1.5rem;
        top: 0;
        transition: transform 0.5s;
        width: auto;
        max-width: 25vw;
        @include responsive(tablet) {
            max-width: 40vw;
        }
        @include responsive (mobile) {
            max-width: 60vw;
        }
        img {
            height: auto;
            width: 100%;
        }
        &.watched:after {
            background-color: transparentize($nationalGridGreen, 0.1);
            background-repeat: no-repeat;
            background-size: contain;
            content: 'You have watched this video - click to watch again';
            color: white;
            right: 0;
            position: absolute;
            bottom: 0;
            width: auto;
            padding: 1rem;
            }
            &:hover {
                cursor: pointer;
                transform: scale(1.05);
        }
    }
    .btn {
        animation-delay: 0.5s;
        animation-fill-mode: backwards;
        animation: dropIn 1s;
        background: $nationalGridLightBlue;
        box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
        color: white;
        display: inline-block;
        margin-top: 0.5rem;
        padding: 1rem 0.5rem;
        text-align: center;
        transition: transform 0.5s, box-shadow 0.5s;
        width: 100%;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
            cursor: pointer;
            transform: translateY(-2px);
        }
        &.small {
            background: $nationalGridGreen;
            color: #fff;
            font-size: 1.1rem;
            left: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2rem;
            padding: 1rem 2rem;
            position: relative;
            text-shadow: rgba(0, 0, 0, 0.5) 0 0 8px;
            transform-origin: 0% 50%;
            transform: translateX(-50%);
            width: auto;
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
                transform: translateY(-2px) translateX(-50%);
            }
            &.greyed {
                background: #aaa;
                box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 4px;
            }
            &.introvideo {
                left: 0;
                margin-top: 2rem;
                transform: none;
            }
        }
        &.yes {
            background: #aaa;
        }
    }
}
</style>
