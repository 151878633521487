<template>
<div>
    <vue-slider v-model="retirementAge" :lazy="false" :piecewise="true"
        :speed="0" :min="55" :tooltip="false" :max="65" :dotSize="28">
        <template slot="label" slot-scope="{ label, active }">
            <span class="vue-slider-piecewise-label">{{ label }}</span>
        </template>
        <template slot="piecewise" slot-scope="{ label, active }">
            <span class="vue-slider-piecewise-dot year" :class="{ active: active }"/>
        </template>
    </vue-slider>
    <vue-slider v-model="cashAmountPercentage" :real-time="true" :data="cashSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="100" :dotSize="28">
    <template slot="label" slot-scope="{ label, active }">
        <span class="vue-slider-piecewise-label" v-if="label === 0">Minimum</span>
        <span class="vue-slider-piecewise-label" v-if="label === 100">Maximum</span>
    </template>
    <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 10 === 0"></span></template>
    </vue-slider>
    <input id="nobridging" type="radio" name="bridge" checked="true" v-model="bridgeTheGap" :value="false"/>
    <label class="card" for="nobridging">
        No Bridge
    </label>
    <input id="bridging" type="radio" name="bridge" v-model="bridgeTheGap" :value="true"/>
    <label class="card" for="bridging">
        Bridge
    </label>
    <input id="jointLife" type="radio" name="spouseBenefits" checked="true" v-model="spouseBenefit" :value="0"/>
    <label class="card" for="jointLife">
        Joint life
    </label>
    <input id="singleLife" type="radio" name="spouseBenefits" v-model="spouseBenefit" :value="1"/>
    <label class="card" for="singleLife">
        Single life
    </label>
    <input id="increase" type="radio" name="increases" checked="true" v-model="increasingOrNot" :value="0"/>
    <label class="card" for="increase">
        Increases
    </label>
    <input id="nonIncreasing" type="radio" name="increases" v-model="increasingOrNot" :value="1"/>
    <label class="card" for="nonIncreasing">
        No increases
    </label>
    <input id="smoking" type="radio" name="smoking" checked="true" v-model="smokingOrNot" :value="0"/>
    <label class="card" for="smoking">
        Smoking
    </label>
    <input id="nosmoking" type="radio" name="smoking" v-model="smokingOrNot" :value="1"/>
    <label class="card" for="nosmoking">
        No smoking
    </label>
    <input id="chronic" type="radio" name="chronic" checked="true" v-model="chronicOrNot" :value="0"/>
    <label class="card" for="chronic">
        Chronic
    </label>
    <input id="notchronic" type="radio" name="chronic" v-model="chronicOrNot" :value="1"/>
    <label class="card" for="notchronic">
        Not chronic
    </label>
    <vue-slider v-model="targetAge" :real-time="true" :data="ageSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="70 * 12" :tooltip="false" :max="1260" :dotSize="28">
    <template slot="label" slot-scope="{ label, active }"><span class="vue-slider-piecewise-label" v-if="label % 12 === 0">{{ label / 12 }}</span></template>
    <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 12 === 0"></span></template>
    </vue-slider>
    <vue-slider v-model="taxedCashAmountPercentage" :real-time="true" :data="cashSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="100" :dotSize="28">
    <template slot="label" slot-scope="{ label, active }">
        <span class="vue-slider-piecewise-label" v-if="label === 0">Minimum</span>
        <span class="vue-slider-piecewise-label" v-if="label === 100">Maximum</span>
    </template>
    <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 10 === 0"></span></template>
    </vue-slider>
    <button v-if="!dataLoaded" @click="getData">Get data</button>
    <button v-if="dataLoaded" @click="runCalc">Rerun calc</button>
    <json-excel v-if="calculation"
        :data="output"
        :fields="json_fields"
        type="xls"
        :worksheet="`TestResults v${version}`"
        :name="fileDescription">
        Download Data
    </json-excel>
</div>
</template>

<script>
import vueSlider from "vue-slider-component";
import JsonViewer from 'vue-json-viewer';
import calculations from './../calculations/nationalgrid';
import util from './../lib/util';
import JsonExcel from 'vue-json-excel';
export default {
    name: "BulkTest",
    props: ["schemeId"],
    components: {
        vueSlider,
        JsonViewer,
        JsonExcel
    },
    data () {
        return {
            calculation: false,
            dataLoaded: false,
            ageTest: 60,
            json_fields : {
                'Ref': 'ref',
                'NI': 'ni',
                'Age': 'age',
                'Input - Retirement age': 'schemeCalc.retirement_age',
                'Input - cashAmountPercentage': 'cashAmountPercentage',
                'Input - bridgeTheGap': 'bridgeTheGap',
                'Input - spouseBenefit': 'spouseBenefit',
                'Input - increasingOrNot': 'increasingOrNot',
                'Input - smokingOrNot': 'smokingOrNot',
                'Input - chronicOrNot': 'chronicOrNot',
                'Input - targetAge': 'targetAge',
                'Input - taxedCashAmountPercentage': 'taxedCashAmountPercentage',
                'Scheme pension': 'schemeCalc.retirement_pension',
                'Scheme pcls': 'schemeCalc.retirement_pcls',
                'Scheme gmp step up': 'schemeCalc.gmp_step_up',
                'Scheme spouses': 'schemeCalc.retirement_sp_pen',
                'TV ret': 'annuityCalc.retirement_tv',
                'Annuity pension': 'annuityCalc.retirement_annuity',
                'Annuity pcls': 'annuityCalc.retirement_pcls',
                'Annuity spouses': 'annuityCalc.retirement_sp_pen',
                'Drawdown pension': 'drawdownCalc.retirement_dd_pension',
                'Drawdown pcls': 'drawdownCalc.retirement_pcls',
                'Drawdown taxed pcls': 'drawdownCalc.retirement_pcls_taxed',
                'Life expectancy': 'drawdownCalc.life_expectancy'
            },
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ]
        };
    },
    created () {
        this.version = '1.0.1';
    },
    computed: {
        retirementAge: {
            get () {
                return this.$store.getters.getRetirementAge;
            },
            set (newValue) {
                this.$store.dispatch("setRetirementAge", newValue);
            }
        },
        cashSliderDataPoints () {
            var arr = [];
            for (var i = 0; i <= 100; i++) {
                arr.push(i);
            }
            return arr;
        },
        ageSliderDataPoints () {
            var arr = [];
            for (var i = 70 ; i <= 105; i++) {
                arr.push(i*12);
            }   
            return arr;
        },
        cashAmountPercentage: {
            get () {
                return this.$store.getters.getSchemePensionCashMaxProp;
            },
            set (newValue) {
                this.$store.dispatch('setSchemePensionCashMaxProp', newValue);
            }
        },
        bridgeTheGap: {
            get () {
                return this.$store.getters.getBridgeTheGap;
            },
            set (newValue) {
                this.$store.dispatch('setBridgeTheGap', newValue);
            }
        },
        spouseBenefit: {
            get () {
                return this.$store.getters.getAnnuitySpouseOption? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuitySpouse', newValue === 0);
            }
        },
        smokingOrNot: {
            get () {
                return this.$store.getters.getSmoking? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuitySmoking', newValue === 0);
            }
        },
        chronicOrNot: {
            get () {
                return this.$store.getters.getChronic? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityChronic', newValue === 0);
            }
        },
        increasingOrNot: {
            get () {
                return this.$store.getters.getAnnuityIncreasingOption? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityIncreasing', newValue === 0);
            }
        },
        taxedCashAmountPercentage: {
            get: function () {
                return this.$store.getters.getFlexibleIncomeMaximumTaxedCashProp;
            },
            set: function (newValue) {
                this.$store.dispatch('setflexibleIncomeTaxedCashMaxProp', newValue);
            }
        },
        targetAge: {
            get: function () {
                return this.$store.getters.getFlexibleIncomeAge*12;
            },
            set: function (newValue) {
                this.$store.dispatch('setFlexibleIncomeAge', Math.floor(newValue/12));
            }
        },
        fileDescription () {
            return 'calculation_run_age' + this.ageTest + "_" + this.dateNow() + ".xls";
        }
    },
    methods: {
        getData () {
            this.$store.dispatch("getUserBulk", {
                schemeId: this.schemeId
            }).then(response => {
                this.rawData = response.data;
                this.dataLoaded = true;
            });
        },
        dateNow () {
            let now = new Date();
            let year = "" + now.getFullYear();
            let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
            let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
            let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
            let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
            let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        runCalc () {
            this.calculation = false;
            this.output = [];

            this.ageTest = this.retirementAge;

            this.rawData.forEach(user => {
                let userObj = util.parseJson(user);
                userObj = calculations.initialiseUser(userObj);
                
                this.$store.commit('setUser',  userObj);
                this.$store.commit('setRetirementAge', Math.max(this.ageTest, Math.max(userObj.earliest_retirement_age, Math.floor(userObj.age + 1))));
                let results = this.$store.getters.getCalculationsTest;
                results.ref = userObj.website_reference;
                results.ni = userObj.ni_number;
                results.age = userObj.age;
                results.cashAmountPercentage = this.cashAmountPercentage;
                results.bridgeTheGap = this.bridgeTheGap;
                results.spouseBenefit = this.spouseBenefit === 0;
                results.increasingOrNot = this.increasingOrNot === 0;
                results.smokingOrNot = this.smokingOrNot === 0;
                results.chronicOrNot = this.chronicOrNot === 0;
                results.targetAge = this.targetAge / 12;
                results.taxedCashAmountPercentage = this.taxedCashAmountPercentage;
                this.output.push(this.$store.getters.getCalculationsTest);
            });
            this.calculation = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
@import "./../scss/mixins.scss";

</style>
