<template>
   <div>
       <div class="table-container" v-for="(tableData, i) in tables" :key="i">
           <h3>{{ tableData.name }}</h3>
            <table>
                <thead>
                    <th>Measure</th>
                    <th v-for="month in monthsBetweenDates" :key="month">
                        {{ monthFormat(month) }}
                    </th>
                </thead>
                <tbody>
                    <tr v-for="data in tableData.data" :key="data.name">
                        <td>{{ data.name }}</td>
                        <td v-for="month in monthsBetweenDates" :key="month">
                            {{ getTableStat(data, month) }}
                        </td>
                    </tr>
                </tbody>
            </table>
       </div>
   </div>
</template>

<script>
import analyticsApi from './../api/analytics';
import moment from 'moment';
import find from 'lodash/find';
export default {
    data () {
        return {
            fromDate: '2019-06-11',
            toDate: '2019-09-01',
            userStats: null,
            sessionStats: null,
            videoStats: null,
            videoTimes: null,
            tables: [
                {
                    name: "User statistics",
                    data: [
                        { name: "Unique login attempts", object: 'userStats', id: 48 },
                        { name: "Unique login failures", object: 'userStats', id: 49 },
                        { name: "Unique users entering tool", object: 'userStats', id: 39 },
                        { name: "Total number of sesssions", object: 'sessionStats', id: 39 },
                        { name: "Average time on site", object: 'videoTimes', id: 1, function: this.videoTime },
                    ],
                },
                {
                    name: 'Session page progress',
                    data: [
                        { name: "Sessions getting to Intro", object: 'sessionStats', id: 39, function: this.proportionMadeItTo },
                        { name: "Sessions getting to Options", object: 'sessionStats', id: 45, function: this.proportionMadeItTo },
                        { name: "Sessions getting to What This Means For Me", object: 'sessionStats', id: 46, function: this.proportionMadeItTo},
                        { name: "Sessions getting to What Should I Do Now", object: 'sessionStats', id: 47, function: this.proportionMadeItTo },
                        { name: "Sessions opening to WPSA dialog", object: 'sessionStats', id: 36, function: this.proportionMadeItTo },
                        { name: "Sessions exploring taking financial advice", object: 'sessionStats', id: 38, function: this.proportionMadeItTo },
                        { name: "Sessions opting to print their analysis", object: 'sessionStats', id: 42, function: this.proportionMadeItTo },
                    ],
                },
                {
                    name: 'Total video views',
                    data: [
                        { name: "Sessions watching \"An introduction to MRP\" video", object: 'videoStats', id: 6, function: this.proportionMadeItTo },
                        { name: "Sessions watching \"When can I receive my pension\" video", object: 'videoStats', id: 9, function: this.proportionMadeItTo },
                        { name: "Sessions watching \"What other options do I have\" video", object: 'videoStats', id: 12, function: this.proportionMadeItTo },
                        { name: "Sessions watching \"Intro Tranfer Options\" video", object: 'videoStats', id: 15, function: this.proportionMadeItTo },
                        { name: "Sessions watching \"Pension from an insurer\" video", object: 'videoStats', id: 18, function: this.proportionMadeItTo },
                        { name: "Sessions watching \"Taking flexible income\" video", object: 'videoStats', id: 21, function: this.proportionMadeItTo },
                        { name: "Sessions watching \"Introduction to WPSA\" video", object: 'videoStats', id: 24, function: this.proportionMadeItTo },
                    ],
                },
                {
                    name: 'Total video views when made to the relevant section',
                    data: [
                        { name: "Sessions watching \"An introduction to MRP\" video - who made it to section", object: 'videoStats', id: 6, function: this.proportionMadeItTo, total: 39 },
                        { name: "Sessions watching \"When can I receive my pension\" video - who made it to section", object: 'videoStats', id: 9, function: this.proportionMadeItTo, total: 45 },
                        { name: "Sessions watching \"What other options do I have\" video - who made it to section", object: 'videoStats', id: 12, function: this.proportionMadeItTo, total: 45 },
                        { name: "Sessions watching \"Intro to tranfer options\" video - who made it to section", object: 'videoStats', id: 15, function: this.proportionMadeItTo, total: 45 },
                        { name: "Sessions watching \"Pension from an insurer\" video - who made it to section", object: 'videoStats', id: 18, function: this.proportionMadeItTo, total: 45 },
                        { name: "Sessions watching \"Taking flexible income\" video - who made it to section", object: 'videoStats', id: 21, function: this.proportionMadeItTo, total: 45 },
                        { name: "Sessions watching \"Introduction to WPSA\" video - who made it to section", object: 'videoStats', id: 24, function: this.proportionMadeItTo, total: 47 },
                    ],
                },
                {
                    name: 'Average percentage of videos watched',
                    data: [
                        { name: "Average progress through \"An introduction to MRP\" video", object: 'videoTimes', id: 8, function: this.videoProgress },
                        { name: "Average progress through \"When can I receive my pension\" video", object: 'videoTimes', id: 11, function: this.videoProgress },
                        { name: "Average progress through \"What other options do I have\" video", object: 'videoTimes', id: 14, function: this.videoProgress },
                        { name: "Average progress through \"Intro to tranfer options\" video", object: 'videoTimes', id: 17, function: this.videoProgress },
                        { name: "Average progress through \"Pension from an insurer\" video", object: 'videoTimes', id: 20, function: this.videoProgress },
                        { name: "Average progress through \"Taking flexible income\" video", object: 'videoTimes', id: 23, function: this.videoProgress },
                        { name: "Average progress through \"Introduction to WPSA\" video", object: 'videoTimes', id: 26, function: this.videoProgress },
                    ]
                }
            ]
        };
    },
    mounted () {
    },
    methods: {
        monthFormat (month) {
            return moment(month, 'YYYY-MM').format('YYYY-M');
        },
        proportionMadeItTo (prop, month) {
            const overalTotal = this.getTableStat({ object: 'sessionStats', id: prop.total || 39}, month);
            const sectionTotal = this.getTableStat({ object: prop.object, id: prop.id}, month);
            if (!sectionTotal || !overalTotal) return '';
            return Math.round(sectionTotal / overalTotal * 1000) / 10 + '%';
        },
        videoTime (prop, month) {
            const videoTime = this.getTableStat({ object: prop.object, id: prop.id}, month);
            return Math.round(videoTime / 60 * 100) / 100 + ' mins';
        },
        videoProgress (prop, month) {
            const videoAverage = this.getTableStat({ object: prop.object, id: prop.id}, month);
            let videoLength = 0;
            if (!videoAverage) return '';
            switch (prop.id) {
            case 8: videoLength = 241; break;
            case 11: videoLength = 199; break;
            case 14: videoLength = 107; break;
            case 17: videoLength = 216; break;
            case 20: videoLength = 188; break;
            case 23: videoLength = 201; break;
            case 26: videoLength = 220; break;
            }
            return Math.round(videoAverage / videoLength * 1000) / 10 + '%';
        },
        getTableStat (prop, month) {
            if (prop.function) {
                return prop.function(prop, month);
            }
            const monthData = find(this[prop.object], a => a.key === month);
            if (!monthData) return '';
            const measureData = find(monthData.measures, a => a.key === prop.id);
            if (!measureData) return '';
            return measureData.total;
        },
        getStats (fromDate, toDate) {
            this.fromDate = fromDate;
            this.toDate = toDate;
            analyticsApi.getUserStats({ start: this.fromDate, end: this.toDate }, response => {
                this.userStats = response;
            });
            
            analyticsApi.getSessionStats({ start: this.fromDate, end: this.toDate }, response => {
                this.sessionStats = response;
            });

            analyticsApi.getVideoStats({ start: this.fromDate, end: this.toDate }, response => {
                this.videoStats = response.videosOpened;
                this.videoTimes = response.videoTimes;
            });
        },
    },
    computed: {
        monthsBetweenDates () {
            const months = [];
            let fromDate = moment(this.fromDate);
            while(fromDate < moment(this.toDate)) {
                months.push(fromDate.format('YYYY-MM'));
                fromDate.add(1, 'month');
            }
            return months;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../RetirementPlanner.VueCore/src/scss/variables.scss';
    table {
        width: 100%;
        td, th {
            padding: 0.75rem;
            &:first-child {
                width: 700px;
            }
        }
        thead {
            border-bottom: 1px solid #eee;
            background: #eee;
            color: #444;
        }
        tr {
            border-bottom: 1px solid #eee;
        }
        th {
            text-align: left;
        }
    }
 
 .table-container {
    padding: 1rem;
    margin: 1rem;
    h3 {
        padding: 1rem 0.25rem;
        color: $nationalGridLightBlue;
    }
 }
</style>
      