<template>
   <div>
       <div class="table-container">
           <h3>Data sent to WPSA</h3>
           <p class="total">Total sent: <b>{{ userStats ? userStats.length : '' }}</b></p>
            <table>
                <thead>
                    <th>Requested at</th>
                    <th>Website ref</th>
                    <th>NI Number</th>
                    <th>Scheme number</th>
                </thead>
                <tbody>
                    <tr v-for="data in userStats" :key="data.requestAt">
                        <td>{{ formatDate(data.requestAt) }}</td>
                        <td>{{ data.requestMemberId }}</td>
                        <td>{{ data.niNumber }}</td>
                        <td>{{ data.pensionNumber }}</td>
                    </tr>
                </tbody>
            </table>
       </div>
   </div>
</template>

<script>
import analyticsApi from './../api/analytics';
import moment from 'moment';
export default {
    data () {
        return {
            fromDate: '2019-06-11',
            toDate: '2019-09-01',
            userStats: null,
        };
    },
    mounted () {
    },
    methods: {
        getStats (fromDate, toDate) {
            this.fromDate = fromDate;
            this.toDate = toDate;
            analyticsApi.getWpsaClickthrough({ start: this.fromDate, end: this.toDate }, response => {
                this.userStats = response;
            });
        },
        formatDate (date) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        }
    },
    computed: {
    }
};
</script>

<style lang="scss" scoped>
@import '../../../RetirementPlanner.VueCore/src/scss/variables.scss';
    table {
        width: 100%;
        td, th {
            padding: 0.75rem;
            &:first-child {
                width: 700px;
            }
        }
        thead {
            border-bottom: 1px solid #eee;
            background: #eee;
            color: #444;
        }
        tr {
            border-bottom: 1px solid #eee;
        }
        th {
            text-align: left;
        }
    }

 .table-container {
    padding: 1rem;
    margin: 1rem;
    h3 {
        padding: 1rem 0.25rem;
        color: $nationalGridLightBlue;
    }
 }

 .total {
     color: $nationalGridLightBlue;
     margin-top: -0.75rem;
     padding-left: 0.25rem;
 }
</style>
