import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '~/store';
import HighchartsVue from 'highcharts-vue';
import '../public/SplitText.min';
import '~/directives/focus';
import '~/directives/clickOutside';
Vue.config.productionTip = false;
import IdleVue from 'idle-vue';

Vue.use(IdleVue, {
    idleTime: 900000,
    store
});

Vue.use(HighchartsVue);

Number.prototype.roundDownToNearest = function (d) {
    return "" + Math.floor(this / d) * d;
};

Vue.filter('percent', function (value) {
    return (value * 100).toFixed(0) + '%';
});

Vue.filter('age' , function (value) {
    let floor = Math.floor(value);
    let months = Math.floor(value*12 -floor*12);

    return months > 0? `${floor} and ${months} months` : floor;
});


Vue.filter('roundDown', function (value, nearest) {
    let n = value, c = nearest == undefined? 0 : nearest,
        d = ".",
        t = ",",
        i = String(parseInt(n = Math.abs(+n || 0).roundDownToNearest(c))),
        j = i.length > 3 ? i.length % 3 : 0;

    return (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t);
});

Vue.filter('nameFormat', function (value) {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
});

window.setTimeout(() => {
    let authResponse = localStorage.getItem('hello');
    let authItem = null;
    if (authResponse && authResponse !== '{}') {
        authItem = JSON.parse(authResponse).b2clogin;
    }

    if (authItem && authItem.error && authItem.error.message && authItem.error.message.indexOf('forgotten')> -1) {
        window.location.href = 'https://LcpAuth.b2clogin.com/LcpAuth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Pwd_Reset_MRP_NG&client_id=b03ceaed-58d7-440b-9d03-f26d970a7922&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fmrp-ng.lcp.uk.com&scope=openid&response_type=id_token&prompt=login';
        localStorage.setItem('hello', '{}');
    }
}, 100);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');


