const dateFormat = /^\d{2}(\/)\d{2}(\/)\d{4}/;
const decimalFormat = /^-?\d*\.?\d*$/;

import moment from 'moment'; 

let reviver = function (key, value) {
    if (typeof value === "string" && dateFormat.test(value)) {
        return new moment(value, 'DD/MM/YYYY');
    }
    if (typeof value === "string" && decimalFormat.test(value)) {
        return parseFloat(value);
    }
    return value;
};

let parseJson = function (string) {
    return JSON.parse(string,reviver);
};

export default {
    reviver: reviver,
    parseJson: parseJson
};