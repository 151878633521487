<template>
<footer>
  <h1>
    {{ schemeDetails.company }} UK Pension Scheme – My Retirement Planner</h1>
  <div class="copyright">LCP LLP {{ new Date().getFullYear() }}</div>
</footer>
</template>

<script>
export default {
    name: 'page-footer',
    computed: {
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        }
    }
};
</script>

<style scoped lang="scss">
@import '../../../scss/variables.scss';
footer {
    padding: 0.5rem;
    background: $nationalGridBlue;
    overflow: hidden;
    h1 {
        color: white;
        font-size: 1rem;
        font-weight: 300;
        display: inline-block;
    }
    .copyright {
        float: right;
        color: white;
    }
}
</style>
