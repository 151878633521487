import Vue from 'vue';
import Router from 'vue-router';
import Home from '~/components/Home';
import Test from '~/components/Test';
import BulkTest from '~/components/BulkTest';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/:schemeId/:userId?',
            name: 'Home',
            component: Home,
            props: true
        },
        {
            path: '/:schemeId/test/:userId?',
            name: 'Test',
            component: Test,
            props: true
        },
        {
            path: '/:schemeId/bulktest/run',
            name: 'Bulktest',
            component: BulkTest,
            props: true
        }
    ]
});
