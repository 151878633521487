<template>
<div class="q" :class="[ direction ]" :id="id">
  <transition name="component-fade" mode="out-in">
    <div class="question-container" v-if="active">
      <div class="circle" v-if="firstQuestion"></div>
      <div class="line first" :class="{ 'top': firstQuestion }"></div>
      <div class="question">
        <div class="question-title">{{ title }}</div>
        <slot></slot>
      </div>
      <div class="line" :class="{ bottom: lastQuestion }"></div>
      <div class="circle end" v-if="lastQuestion"></div>
    </div>
  </transition>
</div>
</template>

<script>
import Guid from '../../mixins/guid.js';
export default {
    name: 'Question',
    mixins: [ Guid, ],
    props: ['title', 'animateText', 'order'],
    components: {
    },
    data () {
        return {
            active: false,
            firstQuestion: false,
            hasBeenActive: false,
            id: '',
            lastError: '',
            lastQuestion: false,
            timeline: null,
        };
    },
    created () {
        var guid = 'q' + this.generateGuid();
        this.id = guid;
    },
    mounted () {
        this.$nextTick(() => {
            this.$parent.addQuestion(this);
        });
    },
    destroyed () {
        this.$parent.removeQuestion(this);
    },
    computed: {
        direction () {
            return this.$parent.direction;
        }
    },
    watch: {
        active () {
            if (this.active && !this.hasBeenActive && this.animateText) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.hasBeenActive = true;
                        // var splitText = null;
                        // try {
                        //     splitText = new window.SplitText('#' + this.id + ' .animate');
                        // } catch (e) {
                        //     this.lastError = e;
                        // }
                        // this.timeline = new TimelineLite();
                        // this.timeline.add(TweenMax.to('.questions.container .next', 0.1, { opacity: 0,  scale: 1, 'pointer-events': 'none' }));
                        // this.timeline.add(TweenMax.to('.questions.container .skip', 0.1, { opacity: 1, y: 0, scaleX: 1, scaleY: 1, 'pointer-events': 'all' }));
                        // this.timeline.add(TweenMax.from('#' + this.id + ' .question-title', 1, { opacity: 0, y: -5, scaleX: 0.5, scaleY: 0.5 }));
                        // if (splitText) {
                        //     this.timeline.add(TweenMax.staggerFrom(splitText.chars, 1,  { autoAlpha: 0, scale: 2,  force3D: true }, 0.04));
                        // }
                        // this.timeline.add(TweenMax.fromTo('#' + this.id + ' .post-animate', 1, { opacity: 0, y: 50, scaleX: 0.5, scaleY: 0.5 }, { opacity: 1, y: 0, scaleX: 1, scaleY: 1 }));
                        // this.timeline.add(TweenMax.to('.questions.container .skip', 0.1, { opacity: 0,  scale: 1, 'pointer-events': 'none' }));
                        // this.timeline.add(TweenMax.to('.questions.container .next', 0.1, { opacity: 1, y: 0, scaleX: 1, scaleY: 1, 'pointer-events': 'all' }));
                    });
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
.component-fade-enter-active, .component-fade-leave-active {
    transition: transform 1s ease;
}
.forwards {
    .component-fade-enter {
        transform: translateY(100%);
        position: absolute;
    }
    .component-fade-leave-to {
        position: absolute;
        transform: translateY(-100%);
    }
}
.backwards {
    .component-fade-enter {
        transform: translateY(-100%);
        position: absolute;
    }
    .component-fade-leave-to {
        position: absolute;
        transform: translateY(100%);
    }
}
.question-container {
    width: 100%;
}
.question {
    position: relative;
    width: 100%;
    padding-bottom: 1.5rem;
}
.q {
    position: relative;
    width: 100%;
}
.circle {
    margin-top: 1rem;
    height: 0.6rem;
    width: 1.5rem;
    background: lighten($pipeEndYellow, 5);
    box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
    margin-left: auto;
    margin-right: auto;
    &.end {
        margin-top: 0;
    }
}
.line {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    height: 3.5rem;
    width: 0px;
    border-left: 4px solid lighten($lightPipeYellow, 5);
    border-right: 4px solid lighten($pipeYellow, 5);
    position: relative;
    &.first {
        margin-top: 0;
        &:not(.top)
        {
            height: calc(4.5rem + 30px);
            &:after {
                content: '';
                height: 4rem;
                left: -4px;
                width: 0px;
                border-left: 4px solid lighten($lightPipeYellow, 5);
                border-right: 4px solid lighten($pipeYellow, 5);
                position: absolute;
                top: -4rem;
            }
        }
    }
    &.end {
        height: 8%;
    }
}
.question-title {
    text-align: center;
    font-size: 1.7rem;
    margin: 1.5rem;
    font-weight: 300;
    @include responsive(mobile) {
        margin-top: 8rem;
    }
}
input[type="text"] {
    background: #eee;
    border: none;
    padding: 1rem;
    width: 200px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: inset 2px 2px 2px rgba(0,0,0,.2);
}
</style>
