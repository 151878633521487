<template lang="pug">
    .input-container(v-click-outside="clickedOutside", @click.self="focused = false", :class="{ disabled: disabled }")
        label.required(:for="id",  @click.self="focused = false", :class="{ indented: indented }") {{ name }}
        input.validate(:class="{ dirty: dirty, error: message, valid: !message }", :disabled="disabled", required="required", type="text", :id="id", :value="value", @input="$emit('input', $event.target.value)", v-focus="focused", ref="inputBox")
        // .validation(v-if="dirty") {{ message }}
        .calendarContainer(v-show="focused", :style="{ left: xPos + 'px', top: yPos  + 'px' }")
            .calendar
                .month
                    a.previousMonth(href="javascript:void(0)", @click="previousYear", debounce="500")
                        i.fa.fa-angle-left(aria-hidden="true")
                            svg(width="20", height="20", viewBox="0 0 1792 1792", xmlns="http://www.w3.org/2000/svg")
                                path(d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z" fill="#666")
                    .monthName {{ year }}
                    a.nextMonth(href="javascript:void(0)", @click="nextYear", debounce="500")
                        i.fa.fa-angle-right(aria-hidden="true")
                            svg(width="20", height="20", viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg")
                                path(d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z", fill="#666")
                .month
                    a.previousMonth(href="javascript:void(0)", @click="previousMonth", debounce="500")
                        i.fa.fa-angle-left(aria-hidden="true")
                            svg(width="20", height="20", viewBox="0 0 1792 1792", xmlns="http://www.w3.org/2000/svg")
                                path(d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z" fill="#666")

                    .monthName {{ monthName }}
                    a.nextMonth(href="javascript:void(0)", @click="nextMonth", debounce="500")
                        i.fa.fa-angle-right(aria-hidden="true")
                            svg(width="20", height="20", viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg")
                                path(d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z", fill="#666")

                ul.daysOfWeek
                    li Mon
                    li Tue
                    li Wed
                    li Thu
                    li Fri
                    li Sat
                    li Sun
                ul.dates
                    li(:key="formattedDate(date)", :class="{ differentMonth: differentMonth(date), selected: selectedDate(date), 'out-of-range': outOfRange(date) }", v-for="date in dates", @click="$emit('input', formattedDate(date))") {{ displayDate(date) }}

</template>

<script>
import Guid from '../../mixins/guid.js';
import moment from 'moment';
import * as _ from 'lodash';

export default {
    props: [ 'value', 'name', 'maxDate', 'minDate', 'disabled', 'indented', ],
    name: 'date-picker',
    mixins: [ Guid, ],
    data: function () {
        return {
            id: '',
            dirty: false,
            dates: [],
            month: 1,
            year: 2017,
            focused: false,
            xPos: 0,
            yPos: 0,
        };
    },
    created: function () {
        this.id = this.generateGuid();
        this.month = moment(new Date()).month();
        this.year = moment(new Date()).year();
        if (moment(this.value, 'DD/MM/YYYY').isValid()) {
            this.month = moment(this.value, 'DD/MM/YYYY').month();
            this.year = moment(this.value, 'DD/MM/YYYY').year();
        }
        this.calendarView();
    },
    mounted () {
        this.xPos = this.$refs.inputBox.offsetLeft;
        this.yPos = this.$refs.inputBox.offsetTop + this.$refs.inputBox.offsetHeight;
    },
    watch: {
        value: function () {
            this.dirty = true;
            if (moment(this.value, 'DD/MM/YYYY').isValid()) {
                this.month = moment(this.value, 'DD/MM/YYYY').month();
                this.year = moment(this.value, 'DD/MM/YYYY').year();
                this.dates = [];
                this.calendarView();
                this.focused = false;
            }
        },
        focused: function () {
            if (this.focused) {
                this.xPos = this.$refs.inputBox.offsetLeft;
                this.yPos = this.$refs.inputBox.offsetTop + this.$refs.inputBox.offsetHeight;
            }
            this.dirty = true;
        },
    },
    computed: {
        monthName: function () {
            return moment(new Date(this.year, this.month, 1)).format('MMMM').toString();
        },
        message () {
            var date = moment(this.value, 'DD/MM/YYYY');
            if (!date.isValid() || date.format('DD/MM/YYYY') !== this.value) {
                return 'Date must be dd/mm/yyyy format';
            }
            if (date < this.minDate) {
                return 'Date must be greater than ' + moment(this.minDate).format('DD/MM/YYYY') + '.';
            }
            if (date > this.maxDate) {
                return 'Date must be less than ' + moment(this.maxDate).format('DD/MM/YYYY') + '.';
            }
            return null;
        },
    },
    methods: {
        clickedOutside: function () {
            this.focused = false;
        },
        formattedDate: function (date) {
            return moment(date).format('DD/MM/YYYY');
        },
        selectedDate: function (date) {
            return this.value === moment(date).format('DD/MM/YYYY');
        },
        differentMonth: function (date) {
            return moment(date).month() !== this.month;
        },
        outOfRange (date) {
            return moment(date) > this.maxDate || moment(date) < this.minDate;
        },
        displayDate: function (date) {
            return moment(date).date();
        },
        dateChanged: function (date) {
            this.$emit('dateChanged', date);
        },
        daysInMonth: function () {
            return new Date(this.year, this.month, 0).getDate();
        },
        dayOfWeek: function (date) {
            var day = moment(date).day();
            if (day === 0) {
                return 7;
            }
            return day;
        },
        calendarView: function () {
            var currentDate = new Date(this.year, this.month, 1);
            while (this.dayOfWeek(currentDate) !== 1) {
                currentDate = moment(currentDate).subtract(1, 'days');
                this.dates.push(currentDate);
            }
            this.dates = _.sortBy(this.dates);
            currentDate = new Date(this.year, this.month, 1);
            while (this.dates.length < 42) {
                this.dates.push(currentDate);
                currentDate = moment(currentDate).add(1, 'days');
            }
        },
        nextMonth: function () {
            this.dates = [];
            this.month++;
            if (this.month === 12) {
                this.month = 0;
                this.year++;
            }
            this.calendarView();
        },
        previousMonth: function () {
            this.dates = [];
            this.month--;
            if (this.month === -1) {
                this.month = 11;
                this.year--;
            }
            this.calendarView();
        },
        nextYear: function () {
            this.dates = [];
            this.year++;
            this.calendarView();
        },
        previousYear: function () {
            this.dates = [];
            this.year--;
            this.calendarView();
        },
    },
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
$dateWidth: 3.5rem;
$labelColour: #000;
$inputShadow: inset rgba(0, 0, 0, 0.24) 2px 2px 6px 0px;

@keyframes calendar {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.calendarContainer {
    position: absolute;
    width: 300px;
    background: white;
    border: 1px solid $borderColour;
    z-index: 1;
    padding: 0.5rem;
    font-size: 0.9rem;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 4px 6px 0px;
    min-width: (7.5 * $dateWidth);
    animation: calendar 0.5s;
    transform-origin: top center;
    margin-top: -1px;
    @include responsive(mobile) {
        margin-left: 0;
        margin-top: 0;
        left: 50%;
        transform: translateX(-50%);
        animation: none;
    }
}

.calendar {
    width: (7 * $dateWidth);
    margin-left: auto;
    margin-right: auto;
    height: 22.6rem;
}

.month {
    color: $fontColour;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    margin: 0.5rem 0;
    text-transform: uppercase;
    font-weight: 300;
    a {
        color: $labelColour;
        padding: 0 1rem;
        font-size: 2rem;
        vertical-align: text-bottom;
        position: absolute;
        &.previousMonth {
            left: 0;
            top: 0rem;
        }
        &.nextMonth {
            right: 0;
            top: 0rem;
        }
    }
}

.monthName {
    display: inline-block;
    font-size: 1.2rem;
}

li {
    width: $dateWidth;
    height: 2.5rem;
    padding: 0.7rem;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    &.differentMonth {
        background: darken(white, 3);
    }
    &.selected {
        box-shadow: $inputShadow;
        background: $highlightColour;
        color: white;
    }
    &.out-of-range {
        opacity: 0.3;
        pointer-events: none;
    }
}

.daysOfWeek {
    color: $labelColour;
    border-bottom: 1px solid $borderColour;
    margin-bottom: 1rem;
    li {
        height: auto;
        padding: 0.5rem 0;
    }
}

.dates {
    position: absolute;
    top: 8.6rem;
    li {
        &:hover {
            cursor: pointer;
            box-shadow: $inputShadow;
            background: $highlightColour;
            opacity: 0.8;
            color: white;
        }
    }
}

@keyframes calendarIn {
    from {
        transform: translateX(-10%);
    }
    to {
        transform: translateX(0);
    }
}


</style>
