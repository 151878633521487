<template>
<div>
    <vue-slider v-model="retirementAge" :lazy="false" :piecewise="true"
        :speed="0" :min="50" :tooltip="false" :max="65" :dotSize="28">
        <template slot="label" slot-scope="{ label }">
            <span class="vue-slider-piecewise-label">{{ label }}</span>
        </template>
        <template slot="piecewise" slot-scope="{ active }">
            <span class="vue-slider-piecewise-dot year" :class="{ active: active }"/>
        </template>
    </vue-slider>
    <vue-slider v-model="cashAmountPercentage" :real-time="true" :data="cashSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="100" :dotSize="28">
    <template slot="label" slot-scope="{ label }">
        <span class="vue-slider-piecewise-label" v-if="label === 0">Minimum</span>
        <span class="vue-slider-piecewise-label" v-if="label === 100">Maximum</span>
    </template>
    <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 10 === 0"></span></template>
    </vue-slider>
    <input id="nobridging" type="radio" name="bridge" checked="true" v-model="bridgeTheGap" :value="false"/>
    <label class="card" for="nobridging">
        No Bridge
    </label>
    <input id="bridging" type="radio" name="bridge" v-model="bridgeTheGap" :value="true"/>
    <label class="card" for="bridging">
        Bridge
    </label>
    <input id="jointLife" type="radio" name="spouseBenefits" checked="true" v-model="spouseBenefit" :value="0"/>
    <label class="card" for="jointLife">
        Joint life
    </label>
    <input id="singleLife" type="radio" name="spouseBenefits" v-model="spouseBenefit" :value="1"/>
    <label class="card" for="singleLife">
        Single life
    </label>
    <input id="increase" type="radio" name="increases" checked="true" v-model="increasingOrNot" :value="0"/>
    <label class="card" for="increase">
        Increases
    </label>
    <input id="nonIncreasing" type="radio" name="increases" v-model="increasingOrNot" :value="1"/>
    <label class="card" for="nonIncreasing">
        No increases
    </label>
    <input id="smoking" type="radio" name="smoking" checked="true" v-model="smokingOrNot" :value="0"/>
    <label class="card" for="smoking">
        Smoking
    </label>
    <input id="nosmoking" type="radio" name="smoking" v-model="smokingOrNot" :value="1"/>
    <label class="card" for="nosmoking">
        No smoking
    </label>
    <input id="chronic" type="radio" name="chronic" checked="true" v-model="chronicOrNot" :value="0"/>
    <label class="card" for="chronic">
        Chronic
    </label>
    <input id="notchronic" type="radio" name="chronic" v-model="chronicOrNot" :value="1"/>
    <label class="card" for="notchronic">
        Not chronic
    </label>
    <vue-slider v-model="targetAge" :real-time="true" :data="ageSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="70 * 12" :tooltip="false" :max="1260" :dotSize="28">
    <template slot="label" slot-scope="{ label }"><span class="vue-slider-piecewise-label" v-if="label % 12 === 0">{{ label / 12 }}</span></template>
    <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 12 === 0"></span></template>
    </vue-slider>
    <vue-slider v-model="taxedCashAmountPercentage" :real-time="true" :data="cashSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="100" :dotSize="28">
    <template slot="label" slot-scope="{ label }">
        <span class="vue-slider-piecewise-label" v-if="label === 0">Minimum</span>
        <span class="vue-slider-piecewise-label" v-if="label === 100">Maximum</span>
    </template>
    <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 10 === 0"></span></template>
    </vue-slider>

    <div>{{calcs.schemeCalc.retirement_pension | roundDown(500) }}</div>

    <json-viewer v-if="calcs != null"
    :value="calcs.schemeCalc"
    copyable
    ></json-viewer>
    <json-viewer v-if="calcs != null"
    :value="calcs.annuityCalc"
    copyable
    ></json-viewer>
    <json-viewer v-if="calcs != null"
    :value="calcs.drawdownCalc"
    copyable
    ></json-viewer>
    <json-viewer
    :value="user"
    copyable
    boxed></json-viewer>
</div>
</template>

<script>
import vueSlider from "vue-slider-component";
import JsonViewer from 'vue-json-viewer';
export default {
    name: "Tester",
    props: ["schemeId", "userId"],
    components: {
        vueSlider,
        JsonViewer
    },
    data () {
        return {
        };
    },
    created () {
        let user = this.userId;
        if (this.userId === undefined) user = "example";
        this.$store.dispatch("getUser", {
            userId: user,
            schemeId: this.schemeId
        });
    },
    computed: {
        user () {
            return this.$store.getters.getUser;
        },
        calcs () {
            return this.user == null? null : this.$store.getters.getCalculationsTest;
        },
        retirementAge: {
            get () {
                return this.$store.getters.getRetirementAge;
            },
            set (newValue) {
                this.$store.dispatch("setRetirementAge", newValue);
            }
        },
        cashSliderDataPoints () {
            var arr = [];
            for (var i = 0; i <= 100; i++) {
                arr.push(i);
            }
            return arr;
        },
        ageSliderDataPoints () {
            var arr = [];
            for (var i = 70 ; i <= 105; i++) {
                arr.push(i*12);
            }
            return arr;
        },
        cashAmountPercentage: {
            get () {
                return this.$store.getters.getSchemePensionCashMaxProp;
            },
            set (newValue) {
                this.$store.dispatch('setSchemePensionCashMaxProp', newValue);
            }
        },
        bridgeTheGap: {
            get () {
                return this.$store.getters.getBridgeTheGap;
            },
            set (newValue) {
                this.$store.dispatch('setBridgeTheGap', newValue);
            }
        },
        spouseBenefit: {
            get () {
                return this.$store.getters.getAnnuitySpouseOption? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuitySpouse', newValue === 0);
            }
        },
        smokingOrNot: {
            get () {
                return this.$store.getters.getSmoking? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuitySmoking', newValue === 0);
            }
        },
        chronicOrNot: {
            get () {
                return this.$store.getters.getChronic? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityChronic', newValue === 0);
            }
        },
        increasingOrNot: {
            get () {
                return this.$store.getters.getAnnuityIncreasingOption? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityIncreasing', newValue === 0);
            }
        },
        taxedCashAmountPercentage: {
            get: function () {
                return this.$store.getters.getFlexibleIncomeMaximumTaxedCashProp;
            },
            set: function (newValue) {
                this.$store.dispatch('setflexibleIncomeTaxedCashMaxProp', newValue);
            }
        },
        targetAge: {
            get: function () {
                return this.$store.getters.getFlexibleIncomeAge*12;
            },
            set: function (newValue) {
                this.$store.dispatch('setFlexibleIncomeAge', Math.floor(newValue/12));
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
@import "./../scss/mixins.scss";

</style>
