const state = {
    areYouSureIntro: false,
    showIntro: false,
    introVideoWatched: false,
    selectedOption: '',
    showQuestions: false,
    animationPlaying: true,
    schemePensionWatched: false,
    annuityWatched: false,
    drawdownWatched: false,
    cashWatched: true,
    combinationWatched: true,
    whatThisMeansForMeVisible: false,
    areYouSure: false,
    schemePensionOpened: false,
    annuityOpened: false,
    drawdownOpened: false,
    cashOpened: true,
    combinationOpened: true,
    transferringOut: false,
    whatShouldIDoNowVisible: false,
    areYouSureWhatNow: false,
    factFindVisible: false,
    introToTransferOptionsWatched: false,
    otherOptionsWatched: false,
    submittedWpsa: false,
    showEndSection: false,
    retiringInTwoYears: 0,
    feedbackExtra: false
};

const getters = {
    getValue: s => prop => s[prop]
};

const actions = {
    setValue ({ commit }, { prop, value }) {
        commit('setValue', { prop, value });
    }
};

const mutations = {
    setValue (state, { prop, value }) {
        state[prop] = value;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
