<template>
<modal title="Fact find" @close="$emit('close')" :shortIntro="true">
  <template slot="intro"></template>
  <template slot="content">
    <questions-flow ref="questions" @complete="submit">
      <question title="Introduction" :animateText="true">
        <div class="container left">
          <p class="animate">Your responses to the information requested are designed to help provide advice in relation to your retirement options or Pension Plans and all questions are relevant and should be answered.</p>
          <p class="animate">Please  note that it  is  your right  not to provide  all the information requested.    However,  this could have an effect on the advice you are provided. </p>
        </div>
      </question>
      <question v-for="(level, levelKey) in userDetails" :key="levelKey" :title="level.label" v-if="spouseDetails">
        <table class="fact-find-table">
          <thead v-if="level.spouse">
            <tr>
              <th></th>
              <th><span v-if="level.spouse">You</span></th>
              <th v-if="level.spouse">Your spouse</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prop, propKey) in level.values" :key="propKey" v-if="prop.showIf === undefined || prop.showIf(level)">
              <td>
                <label>{{ prop.label }}</label>
              </td>
              <td>
                <table-input v-model="userDetails[levelKey].values[propKey].value" :prop="prop"></table-input>
              </td>
              <td v-if="level.spouse">
                <table-input v-model="spouseDetails[levelKey].values[propKey].value" :prop="prop"></table-input>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="dynamic-table" v-if="level.rowSchema &amp;&amp; (level.rowIf === null || level.rowIf(level))">
          <table class="fact-find-table dynamic">
            <thead>
              <tr>
                <th v-for="prop in level.rowSchema" :key="prop.label">
                    {{ prop.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in level.rows" :key="index">
                <td v-for="prop in row" :key="prop.value">
                  <table-input v-model="prop.value" :prop="prop"></table-input>
                </td>
              </tr>
              <tr>
                <td style="padding-top: 1rem"><a class="add-row" @click="addRow(level)">Add dependant</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </question>
    </questions-flow>
  </template>
</modal>

</template>

<script>
import QuestionsFlow from './common/QuestionsFlow';
import Question from './common/Question';
import Modal from  './common/Modal';
import * as _ from 'lodash';
import tableInput from './factfind/tableInput';
export default {
    name: 'FactFind',
    components: { QuestionsFlow, Question, Modal, tableInput },
    data () {
        return {
            mounted: false,
            spouseDetails: null,
            userDetails: null
        };
    },
    methods: {
        submit () {
            this.$emit('submit');
        },
        addRow (level) {
            level.rows.push(_.cloneDeep(level.rowSchema));
        }
    },
    computed: {
        storeUserDetails: {
            get ()  { 
                return this.$store.getters.getUserDetails;
            }
        },
        storeSpouseDetails: {
            get ()  { 
                return this.$store.getters.getSpouseDetails;
            }  
        }
    },
    mounted () {
        this.mounted = true;
        this.$store.commit('setupSpouse');
        this.userDetails = _.cloneDeep(this.storeUserDetails);
        this.spouseDetails = _.cloneDeep(this.storeSpouseDetails);
    },
    watch: {
        userDetails: {
            handler (val) {
                this.$store.dispatch('setUserDetails', val);
            },
            deep: true
        },
        spouseDetails: {
            handler (val) {
                this.$store.dispatch('setSpouseDetails', val);
            },
            deep: true
        },
    }
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';

.dynamic-table {
    padding: 1rem;
    margin-top: 3rem;
}
.fact-find-table {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    td, th {
        padding: 0.5rem;
        text-align: left;
        vertical-align: middle;
    }
    th {
        padding: 0.5rem;
        border-bottom: 2px solid lighten($highlightColour, 20);
        text-align: center;
    }
    tr:first-child td {
        padding-top: 1rem;
    }
}

.add-row {
    background: $highlightColour;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 2rem;
    transition: background 0.5s;

    &:hover {
        cursor: pointer;
        background: lighten($highlightColour, 10);
    }
}


</style>
