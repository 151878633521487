
<template>
<div>
    <slot></slot>
    <div class="row">
        <div class="col-md-12">{{ title }} {{ rangeDescription }}</div>
    </div>
    <div class="row sliderarea">
        <div class="col-md-12">
            <vue-slider
                ref="slider"
                :real-time="true"
                piecewise="dataPoints.length"
                piecewiseLabel="dataPoints.length"
                :speed="0" :data="dataPoints"
                :value="currentValue"
                :min="min"
                :tooltip="false"
                :max="max"
                @click="startSlide"
                @drag-start="startSlide"
                @callback="changed"
                @drag-end="endSlide"
                :dotSize="28"/>
        </div>
    </div>
</div>
</template>

<script>
import vueSlider from 'vue-slider-component';
export default {
    props: ['id', 'title', 'value', 'min', 'max', 'labels', 'dataPoints'],
    components: {
        vueSlider
    },
    data () {
        return {
            currentValue: 0,
            dragging: false
        };
    },
    mounted () {
        this.currentValue = this.value;
    },
    methods: {
        startSlide () {
            this.dragging = true;
            // this.$emit('input', event.value);
        },
        endSlide () {
            this.dragging = false;
            this.$emit('input', this.currentValue);
        },
        changed (val) {
            this.currentValue = val;
            if (!this.dragging) {
                this.$emit('input', val);
            }
        }
    },
    computed: {
        rangeDescription () {
            return this.currentValue;
        }
    },
    watch: {
        'id' () {
            this.currentValue = [this.min, this.max];
            window.setTimeout(() => {
                if (this.$refs.slider) {
                    this.$refs.slider.refresh();
                }
            }, 100);
        },
        value () {
            if (this.currentValue !== this.value) {
                this.currentValue = this.value;
            }
            if (!this.dragging) {
                window.setTimeout(() => {
                    if (this.$refs.slider) {
                        this.$refs.slider.refresh();
                    }
                }, 100);
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import './../../scss/variables.scss';
.sliderarea .vue-slider-wrap, .sliderarea .vue-slider-component {
    .vue-slider-dot {
        background-image: linear-gradient(to bottom, $nationalGridGreen 0%, $nationalGridGreen 100%) !important;
    }
    &:hover .vue-slider-dot {
        background-image: linear-gradient(to bottom, $highlightColour 0%, darken($highlightColour, 20) 100%) !important;
    }
    .vue-slider {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0.23) 100%) !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
    }
    .vue-slider-process {
        background-image: linear-gradient(to right, $nationalGridGreen 0%, $nationalGridGreen 100%) !important;
    }
    &:hover .vue-slider-process {
        background-image: linear-gradient(to right, $highlightColour 0%, darken($highlightColour, 10) 100%) !important;
    }
    .hide {
        display: none !important;
    }
}
</style>
