<template>
  <header>
    <div class="container" v-if="schemeDetails">
      <img v-if="schemeDetails.logo" :src="schemeDetails.logo" alt="NG Logo">
      <h1>{{ schemeDetails.title }}</h1>
      <button
        class="hamburger"
        title="Menu"
        :class="{visible: menuVisible }"
        @click="menuVisible = !menuVisible"
        ref="hamburger"
      >
        <div class="line"/>
        <div class="line"/>
        <div class="line"/>
      </button>
      <nav :class="{ down: admin }">
        <ul>
          <li>
            <a href="javascript:void(0)" @click="logoutFunction">Logout</a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$store.commit('print', true);" v-if="(!admin && !noAccount) || (admin && (permissionLevel === 1 || permissionLevel === 3))">Print</a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="usefulLinksVisible = true" v-if="(!admin && !noAccount) || (admin && (permissionLevel === 1 || permissionLevel === 3))">Useful Links</a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$store.commit('showFeedback', true)" v-if="(!admin && !noAccount) || (admin && (permissionLevel === 1 || permissionLevel === 3))">Feedback</a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="assumptionsVisible = true" v-if="(!admin && !noAccount) || (admin && (permissionLevel === 1 || permissionLevel === 3))">Assumptions</a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="termsVisible = true" v-if="(!admin && !noAccount) || (admin && (permissionLevel === 1 || permissionLevel === 3))">Terms and Conditions</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="terms-container" v-if="termsVisible" @click.self="termsVisible = false">
      <div class="terms">
        <terms/>
        <button @click="termsVisible = false">Ok</button>
      </div>
    </div>
    <div class="terms-container" v-if="assumptionsVisible" @click.self="assumptionsVisible = false">
      <div class="terms">
        <assumptions/>
        <button @click="assumptionsVisible = false">Ok</button>
      </div>
    </div>
    <div class="terms-container" v-if="usefulLinksVisible" @click.self="usefulLinksVisible = false">
      <div class="terms useful">
        <usefulLinks/>
        <button @click="usefulLinksVisible = false">Ok</button>
      </div>
    </div>
  </header>
</template>

<script>
import terms from "./../terms";
import assumptions from "./../assumptions";
import usefulLinks from "./../usefulLinks";
export default {
    name: "page-header",
    props: {
      admin: Boolean,
      permissionLevel: Number,
      logoutFunction: Function
    },
    components: { terms, assumptions, usefulLinks },
    computed: {
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        noAccount () {
            return this.$store.getters.getNoAccount;
        },
    },
    data () {
        return {
            menuVisible: false,
            termsVisible: false,
            assumptionsVisible: false,
            usefulLinksVisible: false
        };
    }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables.scss";
@import "../../../scss/mixins.scss";
header {
  background: $nationalGridBlue;
  overflow: visible;
  h1 {
    color: white;
    font-size: 1.5rem;
    font-weight: 300;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.25rem;
  }
  img {
    max-height: 30px;
    vertical-align: middle;
    display: inline-block;
    max-width: calc(80vw - 40px);
    width: auto;
  }
  .container {
    overflow: visible;
    padding-top: 2rem;
    @include responsive(tablet) {
      padding: 2rem 2rem 1rem 2rem;
    }
  }
  nav {
    height: 0;
    overflow: hidden;
    position: fixed;
    top: 60px;
    right: 2rem;
    background: $nationalGridLightBlue;
    z-index: 30;
    width: 12vw;
    min-width: 200px;
    transition: all 0.5s;
    &.down {
      top: 100px;
    }
    li {
      a {
        padding: 1rem;
        color: #fff;
        display: block;
        width: 100%;
      }
      &:hover {
        background: $nationalGridOrange;
      }
    }
  }
  .hamburger {
    background: none;
    border: none;
    vertical-align: middle;
    position: fixed;
    right: 2rem;
    margin-top: -0.25rem;
    z-index: 30;
    transition: all 0.5s;
    cursor: pointer;
    .line {
      width: 30px;
      height: 3px;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.2) 0 0 2px 2px;
      margin: 5px 0;
      transition: all 0.5s;
    }
    &.visible {
      .line:nth-child(1) {
        transform: rotate(45deg) translateY(6px) translateX(5px);
      }
      .line:nth-child(2) {
        opacity: 0;
      }
      .line:nth-child(3) {
        transform: rotate(-45deg) translateY(-6px) translateX(5px);
      }
      & ~ nav {
        border-top: 0.5rem solid $nationalGridGreen;
        padding-top: 0.5rem;
        height: 272px;
      }
    }
  }
}

.terms-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
}
.terms {
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 0 9999px;
  background: white;
  left: 50%;
  height: 70vh;
  max-height: 700px;
  max-width: 1000px;
  padding: 1.5rem;
  position: absolute;
  top: 7rem;
  transform: translateX(-50%);
  width: 100%;
  z-index: 999;
  &.useful {
    max-height: 500px;
    padding: 0;
    max-width: 700px;
  }
  button {
    background: $nationalGridGreen;
    border: 0;
    bottom: -3rem;
    color: white;
    left: calc(50% - 4rem);
    position: absolute;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
    &.disabled {
      background: #aaa;
      pointer-events: none;
    }
    &:hover,
    &:focus {
      background: darken($nationalGridGreen, 5);
      cursor: pointer;
    }
  }
}
</style>
